import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { AuthHelper } from "../../libs/auth";
import { ApiHelper } from "../../libs/api";
import { Layout } from "../../components";

import {
  MDBBtn,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBValidation,
} from "mdb-react-ui-kit";
import { useAuth } from "../../auth/AuthContext";

const VerifyEmail = () => {
  const {checkAuth} = useAuth()
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    await checkAuth();
    navigate("/accept-term");
  };

  useEffect(() => {
    const fetchEmail = async () => {
      try {
        const { data } = await AuthHelper.me();
        setEmail(data?.email || "");
      } catch (error) {
        console.error('Error fetching email:', error);
      }
    };
    fetchEmail();
  }, []);

  return (
    <Layout>
      <MDBContainer className="d-flex align-items-center justify-content-center">
        <MDBCard
          className="m-5"
          style={{ maxWidth: "600px", minWidth: "400px" }}
        >
          <MDBCardBody className="px-5">
            <h2 className="text-uppercase text-center mb-5">ยืนยันอีเมล</h2>
            <MDBValidation onSubmit={handleSubmit}>
              <p>ระบบได้ส่งลิงค์ยืนยันตัวตนไปที่อีเมล </p>
              {email && <p>{email}</p>}
              <p>สถานะอีเมล: รอการยืนยัน</p>
              <MDBBtn type="submit" className="mb-4 w-100" size="lg">
                ตรวจสอบสถานะ
              </MDBBtn>
              <div className="d-flex align-items-center mb-2">
                <span className="small text-muted">
                  ไม่ได้รับอีเมลใช่หรือไม่?{" "}
                </span>
                <MDBBtn
                  color="link"
                  className="small ms-2"
                  onClick={(e) => {
                    e.preventDefault();
                    ApiHelper.sendVerificationEmail();
                    alert("ส่งอีเมลยืนยันตัวตนแล้ว กรุณาตรวจสอบอีเมลของท่าน");
                  }}
                  style={{ padding: 0 }}
                >
                  ส่งอีเมลใหม่อีกครั้ง
                </MDBBtn>
              </div>
              <p className="small text-muted">
                หรือ กรุณาลองตรวจสอบกล่องอีเมลขยะของคุณ
              </p>
              <p className="text-center">
                <Link className="text-secondary" to="/delete-account">
                  ลบบัญชีผู้ใช้งาน
                </Link>
              </p>
            </MDBValidation>
          </MDBCardBody>
        </MDBCard>
      </MDBContainer>
    </Layout>
  );
};

export default VerifyEmail;
