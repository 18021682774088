import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { AuthProvider } from "./auth/AuthContext";
import { ProtectedRoute } from "./auth/ProtectedRoute";
import { RiskProvider } from "./libs/risk";
import {
  Home,
  Login,
  ForgotPassword,
  Register,
  VerifyEmail,
  AcceptTerm,
  Profile,
  ChangePassword,
  Planning,
  AddEvent,
  FAQ,
  Risk,
  Report,
  Privacy,
  DeleteAccount,
  Notification,
  VerifyIdentity,
  AdminReport,
  AdminAlert,
  AlertAdd,
  ReportDetail,
  LineLogin,
  ThaiDLogin,
  TermOfUse
} from "./pages";

const App = () => {
  return (
    <AuthProvider>
      <RiskProvider>
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/login-line" element={<LineLogin />} />
            <Route path="/login-thaid" element={<ThaiDLogin />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/register" element={<Register />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/term-of-use" element={<TermOfUse />} />
            <Route path="/" element={<Home />} />

            <Route element={<ProtectedRoute requireLogin={true} />}>
              <Route path="/verify-email" element={<VerifyEmail />} />
              <Route path="/delete-account" element={<DeleteAccount />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/change-password" element={<ChangePassword />} />
            </Route>

            <Route element={<ProtectedRoute requireLogin={true} requireEmailVerification={true} />}>
              <Route path="/accept-term" element={<AcceptTerm />} />
            </Route>

            <Route element={<ProtectedRoute requireLogin={true} requireEmailVerification={true} requireAcceptedTerms={true} />}>
              <Route path="/planning" element={<Planning />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/planning/risk" element={<Risk />} />
              <Route path="/report" element={<Report />} />
              <Route path="/notification" element={<Notification />} />
              <Route path="/verify-identity" element={<VerifyIdentity />} />
            </Route>

            <Route element={<ProtectedRoute requireLogin={true} requireEmailVerification={true} requireAcceptedTerms={true} requireVerifiedIdentity={true} />}>
              <Route path="/add-event" element={<AddEvent />} />
            </Route>

            <Route element={<ProtectedRoute requireLogin={true} requireAdmin={true} />}>
              <Route path="/admin-report" element={<AdminReport />} />
              <Route path="/admin-alert" element={<AdminAlert />} />
              <Route path="/alert-add" element={<AlertAdd />} />
              <Route path="/report-detail" element={<ReportDetail />} />
            </Route>

          </Routes>
        </Router>
      </RiskProvider>
    </AuthProvider>
  );
};

export default App;
