import React, { useState } from 'react';

const CustomFileInput = ({setSelectedFile}) => {
  const [preview, setPreview] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className='d-flex justify-content-between align-items-center'>
      <input
        type="file"
        accept="image/*"
        id="imageFileInput"
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
      <label htmlFor="imageFileInput" className={`custom-file-input ${preview ? 'with-preview' : ''}`}>
        <img src="camera.png" alt="Camera Icon" className="camera-icon" />
        {!preview && <span>กรุณาแนบรูปภาพ</span>}
      </label>

      {preview && <img src={preview} alt="Preview" className="image-preview" />}
      <style jsx>{`
        .custom-file-input {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          border: 1px solid #e3e3e3;
          border-radius: .25rem;
          color: #a0a0a0;
          cursor: pointer;
          position: relative;
          background: white;
          padding: .25rem .5rem;
        }

        .custom-file-input.with-preview {
          width: 20%;
          min-height: 40px;
        }

        .custom-file-input span {
          flex-grow: 1;
          text-align: right;
        }

        .camera-icon {
          width: 30px;
          height: 30px;
          transition: all 0.3s ease;
        }

        .image-preview {
          width: 80%;
          max-width: 300px;
          height: auto;
          border: 1px solid #e3e3e3;
          border-radius: 10px;
        }

        .custom-file-input.with-preview .camera-icon {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
        }
      `}</style>
    </div>
  );
};

export default CustomFileInput;
