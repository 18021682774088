import React from 'react';
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit';

const ModalConfirm = ({show, setShow, confirm}) => {
  const toggleOpen = () => setShow(!show);

  return (
    <>
      <MDBModal show={show} backdrop={true} staticBackdrop={true}>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>ยืนยัน</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleOpen}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>คุณต้องการยกเลิกการแจ้งเตือนเหตุนี้หรือไม่</MDBModalBody>
            <MDBModalFooter>
            <MDBBtn color='danger' onClick={confirm}>ยืนยัน</MDBBtn>
              <MDBBtn className='text-danger' color='red-100' onClick={toggleOpen}>
                ยกเลิก
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}

export default ModalConfirm;