import parse from "html-react-parser";
import {
  MDBBtn,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBValidation,
  MDBCheckbox,
} from "mdb-react-ui-kit";

import { PRIVACY_TEXT } from "../../libs/constants";
import { Layout } from "../../components";

const Privacy = () => {
  return (
    <Layout>
      <MDBContainer>
        <MDBCard className="my-4">
          <MDBCardBody>
            <h2 className="text-uppercase text-center">
              นโยบายความเป็นส่วนตัว
            </h2>
            <h2 className="text-uppercase text-center mb-5">
              (Privacy Policy Notice)
            </h2>
            {parse(PRIVACY_TEXT)}
          </MDBCardBody>
        </MDBCard>
      </MDBContainer>
    </Layout>
  );
};

export default Privacy;
