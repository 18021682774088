import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiHelper } from "../../libs/api";
import { Layout, ModalAlert } from "../../components";

import {
  MDBBtn,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBValidation,
  MDBCheckbox,
} from "mdb-react-ui-kit";
import { useAuth } from "../../auth/AuthContext";

const DeleteAccount = () => {
  const {deleteAccount, checkAuth} = useAuth()
  const [formValue, setFormValue] = useState({
    confirm: false,
  });

  const navigate = useNavigate();
  const [modalValue, setModalValue] = useState({
    modalTitle: "",
    modalMessage: "",
    showModal: false,
    onModalClose: () => {},
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formValue?.confirm) {
      const isSuccess = await deleteAccount();
      if (isSuccess) {
        setModalValue({
          modalTitle: "สำเร็จ",
          modalMessage: "ลบข้อมูลบัญผู้ใช้งานสำเร็จ",
          showModal: true,
          onModalClose: async () => {
            setModalValue({ ...modalValue, showModal: false })
            await checkAuth();
            navigate("/")
          }
        });
      }
    }
  };


  return (
    <Layout>
      <MDBContainer className="d-flex align-items-center justify-content-center">
        <MDBCard
          className="m-5"
          style={{ maxWidth: "600px", minWidth: "400px" }}
        >
          <MDBCardBody className="px-5">
            <h2 className="text-uppercase text-center text-danger">
              ลบบัญชีผู้ใช้งาน
            </h2>
            <div
              className="overflow-auto mt-4 mb-5 bg-secondary bg-opacity-25"
              style={{ maxHeight: "200px" }}
            >
              กรุณาทำความเข้าใจรายละเอียดการลบบัญชีผู้ใช้งานของท่านอย่างละเอียดก่อนตัดสินใจลบบัญชีผู้ใช้งาน
              โดยกรุณาคำนึงถึงความสำคัญและความผิดพลาดที่อาจเกิดขึ้นหากท่านตัดสินใจที่จะลบบัญชีของท่านอย่างถาวร
              <br />
              <br />
              การลบบัญชีผู้ใช้งานแอพพลิเคชัน Safe Points
              จะลบข้อมูลทุกอย่างที่เกี่ยวข้องกับบัญชีของท่าน
              ซึ่งรวมถึงข้อมูลส่วนบุคคลของท่าน
              และท่านจะไม่สามารถเข้าใช้งานบัญชีได้อีก
              แต่หากท่านประสงค์จะเข้าใช้งานอีกครั้ง
              ท่านสามารถทำได้โดยกดสมัครเพื่อเข้าใช้งานใหม่อีกครั้ง
              และผ่านกระบวนการสมัครเข้าใช้งานใหม่ตั้งแต่ต้น
              <br />
              <br />
              โปรดทราบว่าทางเราไม่มีช่องทางอื่นใดสำหรับการกู้คืนบัญชีของท่านในอนาคต
              การตัดสินลบบัญชีของท่านจะเป็นการลบบัญชีผู้ใช้งานอย่างถาวร
              ระบบจะไม่มีการเก็บข้อมูลสำรองใด ๆ สำหรับการกู้คืนบัญชีของท่าน
              <br />
              <br />
              ขอขอบคุณที่ใช้บริการแอพพลิเคชัน Safe Points
              และขออภัยในความผิดพลาดต่าง ๆ ที่อาจเกิดขึ้นกับท่าน
              เราหวังว่าจะได้มีโอกาสให้บริการแก่ท่านอีกครั้งในอนาคต
              <br />
              <br />
              ด้วยความเคารพ
              <br />
              ทีมงาน Safe Points
            </div>
            <MDBValidation onSubmit={handleSubmit}>
              <MDBCheckbox
                label="ข้าพเจ้ารับทราบและยืนยันที่จะลบบัญชีผู้ใช้ของข้าพเจ้าอย่างถาวร"
                id="invalidCheck"
                required
                name="confirm"
                onChange={(e) => {
                  setFormValue({ confirm: e.target.checked });
                }}
              />
              <MDBBtn type="submit" className="my-4 w-100 bg-danger" size="lg">
                ยืนยัน
              </MDBBtn>
            </MDBValidation>
          </MDBCardBody>
        </MDBCard>
        <ModalAlert
          show={modalValue.showModal}
          setShow={(show) => (show ? null : modalValue.onModalClose())}
          title={modalValue.modalTitle}
          onClose={modalValue.onModalClose}
        >
          {modalValue.modalMessage}
        </ModalAlert>
      </MDBContainer>
    </Layout>
  );
};

export default DeleteAccount;
