import {
  MDBNavbar,
  MDBNavbarItem,
  MDBNavbarNav,
  MDBTabs,
  MDBTabsContent,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsPane,
} from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useAuth } from "../auth/AuthContext";
import { ApiHelper } from "../libs/api";
import {
  VictoryTheme,
  VictoryPie,
  VictoryLegend,
  VictoryContainer,
} from "victory";

const RiskTypes = {
  life: "ชีวิตและร่างกาย",
  sex: "เพศ",
  property: "ทรัพย์สิน",
  drug: "ยาเสพติด",
};

const RiskReport = ({
  date,
  time,
  provinceData,
  description,
  prob,
  level = 0,
  advices,
}) => {
  const [pieData, setPieData] = useState([]);
  const [selectionType, setSelectionType] = useState(null);
  const [selectedTab, setSelectedTab] = useState("recommendations");
  const { birthday, gender } = useAuth();
  const [moreText, setMoreText] = useState("");

  useEffect(() => {
    const fetchCrimeRules = async () => {
      try {
        await crimeRulesPost();
      } catch (error) {
        console.error("Error fetching crime rules:", error);
      }
    };
    fetchCrimeRules();
    if (prob) {
      const total = Object.values(prob).reduce((a, b) => a + b, 0);
      const data = Object.keys(prob)
        .filter((key) => Math.round((prob[key] * 100) / total) > 1)
        .map((key) => ({
          x: key,
          y: prob[key],
          name: `${RiskTypes[key]} ${Math.round((prob[key] * 100) / total)}%`,
        }));
      setPieData(data);
      if (data.length == 0) setSelectionType(null);
      else setSelectionType(data[0].x);
    }
  }, [prob]);

  const crimeRulesPost = async () => {
    try {
      const { success, data } = await ApiHelper.postCrimeRules({
        gender: gender || null,
        age: birthday || 30,
        province: provinceData,
        date: date,
        time: time,
      });
      if (success) {
        setMoreText(data);
      }
    } catch (error) {
      console.error('Error fetching crime rules:', error);
    }
  };

  return (
    <div className="w-100 d-flex flex-column align-items-center">
      <div
        className="d-flex mb-4 border border-black"
        style={{ width: "100%", borderWidth: "4px" }}
      >
        <button
          className={`btn ${
            selectedTab === "recommendations" ? "btn-secondary" : "btn-light"
          } w-bold fs-5 `}
          onClick={() => setSelectedTab("recommendations")}
          style={{ flex: 1 }}
        >
          คำแนะนำ
        </button>
        <button
          className={`btn ${
            selectedTab === "more" ? "btn-secondary" : "btn-light"
          } w-bold fs-5`}
          onClick={() => setSelectedTab("more")}
          style={{ flex: 1 }}
        >
          เพิ่มเติม
        </button>
      </div>

      {selectedTab === "recommendations" && (
        <div className="text-center">
          <p className="fs-5 mb-0">{description}</p>
        </div>
      )}
      {selectedTab === "more" && (
        <div className="text-center">
          {Object.keys(moreText)
            .filter((key) => key.startsWith("recommendations_"))
            .map((key, index) => (
              <p
                key={key}
                style={{
                  fontSize: 16,
                  textAlign: "center",
                  marginTop: 10,
                }}
              >
                {`${index + 1}. ${moreText[key] || "ไม่มีข้อมูล"}`}
              </p>
            ))}
        </div>
      )}

      {/* <p className="fs-5 mb-0">{description}</p> */}
      {level > 1 && (
        <>
          <hr className="w-100 hr" />
          <p className="fw-bold fs-5">
            อัตราความเสี่ยงของการเกิดเหตุในแต่ละประเภท
          </p>
          <div className="alert alert-info mb-4" role="alert">
            อัตราความเสี่ยงที่แสดงมิได้หมายถึงการเกิดเหตุขึ้นอย่างแน่นอน
            แต่เป็นการบอกโอกาสที่หากเกิดเหตุขึ้น จะเป็นเหตุประเภทใด
            โดยอ้างอิงจากข้อมูลที่หน่วยงานรวบรวมมาได้ เท่านั้น
          </div>
          <VictoryContainer
            width={400}
            height={250 + 30 * Object.keys(pieData).length}
            style={{
              padding: 10,
            }}
          >
            <VictoryPie
              // purple, green, yellow, red, blue
              colorScale={[
                "#52489C",
                "#00798C",
                "#EDAE49",
                "#E63946",
                "#3376BD",
              ]}
              // theme={VictoryTheme.material}
              data={pieData}
              width={400}
              height={300}
              standalone={false}
              labels={() => null}
              padding={{
                top: 15 + 30 * Object.keys(pieData).length,
                bottom: 85 - 30 * Object.keys(pieData).length,
              }}
            />
            <VictoryLegend
              data={pieData}
              standalone={false}
              colorScale={[
                "#52489C",
                "#00798C",
                "#EDAE49",
                "#E63946",
                "#3376BD",
              ]}
            />
          </VictoryContainer>
          <hr className="w-100 hr" />
          <MDBTabs className="mb-4" fill justify style={{ width: "100%" }}>
            <div className="d-flex flex-row w-100">
              {pieData.map((item) => {
                const type = item.x;
                return (
                  <MDBTabsItem key={`tab${type}`}>
                    <MDBTabsLink
                      onClick={() => setSelectionType(type)}
                      active={selectionType == type}
                      style={{ paddingLeft: 0, paddingRight: 0 }}
                    >
                      {RiskTypes[type]}
                    </MDBTabsLink>
                  </MDBTabsItem>
                );
              })}
            </div>
          </MDBTabs>
          <p className="fw-bold fs-5">การปฏิบัติตัว</p>
          <p className="fs-5 mb-0">
            {selectionType
              ? advices?.[selectionType]?.general?.map((item) => {
                  return (
                    <>
                      {item}
                      <br />
                    </>
                  );
                })
              : null}
          </p>
          <hr className="w-100 hr" />
          <p className="fw-bold fs-5">การปฏิบัติตัวเมื่อเกิดเหตุฉุกเฉิน</p>
          <p className="fs-5 mb-0">
            {selectionType
              ? advices?.[selectionType]?.emergency?.map((item) => {
                  return (
                    <>
                      {item}
                      <br />
                    </>
                  );
                })
              : null}
          </p>
        </>
      )}
    </div>
  );
};
export default RiskReport;
