import { NavLink } from "react-router-dom";
import {
  MDBCol,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardImage,
  MDBRipple,
} from "mdb-react-ui-kit";
import { GooglePlayStore, AppStore } from "./StoreButton";
import { GOOGLE_PLAY_URL, APP_STORE_URL } from "../libs/constants";

const RiskAds = ({isAuthenticated}) => {
  return (
    <>
      <div
        className="p-5 text-center bg-image"
        style={{
          backgroundImage: "url('/bg.svg')",
          height: "600px",
        }}
      >
        <div className="d-flex justify-content-center align-items-center h-100 ">
          <img
            className="img-fluid d-none d-lg-block"
            src="/phone.svg"
            alt="phone"
            style={{
              maxHeight: "450px",
            }}
          ></img>
          <div className="col-lg-6 col-md-10 align-self-center">
            <div className=" mb-1 pt-5">
              <h1
                className="text-start my-0"
                style={{
                  color: "#FFE15C",
                  maxWidth: "550px",
                  fontFamily: "inherit",
                }}
              >
                Safe Points
              </h1>
              <h1
                className="text-start text-white fs-4"
                style={{
                  maxWidth: "550px",
                }}
              >
                ระบบพยากรณ์สถานการณ์อาชญากรรม
              </h1>
              <h4
                className="text-start text-white fw-normal"
                style={{
                  fontSize: "16px",
                  maxWidth: "550px",
                }}
              >
                Safe Points เป็น Application
                ระบบพยากรณ์สถานการณ์อาชญากรรมของสำนักงาน กิจการยุติธรรม
                โดยมีวัตถุประสงค์จะนำข้อมูลของอาชญากรรมที่เคยเกิดขึ้นในพื้นที่มา
                พยากรณ์ความเสี่ยงที่อาจเกิดอาชญากรรม ในเวลาต่าง ๆ
                ด้วยระบบเทคโนโลยีสารสนเทศ ด้านกระบวนการเรียนรู้ของคอมพิวเตอร์
              </h4>
              {!isAuthenticated && (
                <div className="my-4 d-flex flex-row pb-2">
                  <NavLink to="/login">
                    <MDBBtn
                      className="rounded-6"
                      color="secondary"
                      style={{
                        fontSize: "16px",
                      }}
                      to="/login"
                    >
                      เข้าสู่ระบบ
                    </MDBBtn>
                  </NavLink>
                  <NavLink to="/register">
                    <MDBBtn
                      className="mx-2 text-decoration-underline"
                      color="tertiary"
                      rippleColor="light"
                      style={{
                        color: "#FFFFFF",
                        fontSize: "16px",
                      }}
                      to="/register"
                    >
                      สมัครเข้าใช้งาน
                    </MDBBtn>
                  </NavLink>
                </div>
              )}
            </div>
            <div
              className="d-flex flex-row mt-4 mb-5"
              style={{ minWidth: "200px", width: "100%" }}
            >
              <MDBCol style={{ maxWidth: "150px" }}>
                <a href={GOOGLE_PLAY_URL} target="_blank" rel="noreferrer">
                  <GooglePlayStore />
                </a>
              </MDBCol>
              <MDBCol className="mx-1 px-1" style={{ maxWidth: "150px" }}>
                <a href={APP_STORE_URL} target="_blank" rel="noreferrer">
                  <AppStore />
                </a>
              </MDBCol>
            </div>
            <img
              className="img-fluid d-none d-lg-block ms-5 my-2"
              src="/notebook.svg"
              alt="notebook"
              style={{
                maxHeight: "200px",
              }}
            ></img>
          </div>
        </div>
      </div>
      {isAuthenticated ? null : (
        <div>
          <div className="d-flex flex-wrap justify-content-evenly align-items-center my-4">
            <MDBCard
              className="mx-2 my-3 rounded-6 overflow-hidden"
              style={{
                width: "26rem",
              }}
            >
              <MDBRipple
                rippleColor="light"
                rippleTag="div"
                className="p-3 hover-overlay d-flex justify-content-center align-items-center"
                style={{ backgroundColor: "#B6D0E2" }}
              >
                <img src="/feature1.svg" alt="..." className="mx-3" />
                <div
                  className="mask"
                  style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
                />
                <MDBCardTitle
                  className="text-center"
                  style={{
                    color: "#547AC1",
                  }}
                >
                  พยากรณ์อาชญากรรม และวางแผนการเดินทาง
                </MDBCardTitle>
              </MDBRipple>
              <MDBCardBody>
                <MDBCardText className="text-center">
                  ดูข้อมูลพยากรณ์สถานการณ์การเกิดอาชญากรรมได้ตั้งแต่ปัจจุบันจนถึง
                  5 วันข้างหน้า ณ สถานที่ต่าง ๆ
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
            <MDBCard
              className="mx-2 my-3 rounded-6 overflow-hidden"
              style={{
                width: "26rem",
              }}
            >
              <MDBRipple
                rippleColor="light"
                rippleTag="div"
                className="p-3 hover-overlay d-flex justify-content-center align-items-center"
                style={{ backgroundColor: "#B6D0E2" }}
              >
                <MDBCardImage src="/feature2.svg" alt="..." className="mx-3" />

                <div
                  className="mask"
                  style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
                />
                <MDBCardTitle
                  className="text-center"
                  style={{
                    color: "#547AC1",
                  }}
                >
                  แจ้งเตือนเหตุด่วน และค้นหาพื้นที่ปลอดภัย
                </MDBCardTitle>
              </MDBRipple>
              <MDBCardBody>
                <MDBCardText className="text-center">
                  หากเกิดเหตุอาชญากรรมจะ แจ้งเตือนบริเวณใกล้เคียงและ
                  ค้นหาสถานที่สำคัญเพื่อขอความช่วยเหลือได้
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
            <MDBCard
              className="mx-2 my-3 rounded-6 overflow-hidden"
              style={{
                width: "26rem",
              }}
            >
              <MDBRipple
                rippleColor="light"
                rippleTag="div"
                className="p-3 hover-overlay d-flex justify-content-center align-items-center"
                style={{ backgroundColor: "#B6D0E2" }}
              >
                <MDBCardImage src="/feature3.svg" alt="..." />

                <div
                  className="mask"
                  style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
                />

                <MDBCardTitle
                  className="text-center"
                  style={{
                    color: "#547AC1",
                  }}
                >
                  แจ้งข้อมูลข่าวสารเพื่อจากประชาชน
                </MDBCardTitle>
              </MDBRipple>
              <MDBCardBody>
                <MDBCardText className="text-center">
                  คุณสามารถแจ้งข้อมูลของสถานที่ที่คุณรู้สึกไม่ปลอดภัย
                  เพื่อเป็นส่วนหนึ่งในการป้องกันการเกิดเหตุอาชญากรรม
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </div>
        </div>
      )}
    </>
  );
};

export default RiskAds;
