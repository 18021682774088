import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiHelper } from "../../libs/api";
import {
  DEFAULT_ERROR_MESSAGE,
  USE_GMAP_SDK,
  USE_PLACE_API,
  USE_GEO_API,
  GOOGLE_MAPS_API_KEY,
} from "../../libs/constants";
import { MDBBtn, MDBContainer, MDBCard, MDBCardBody } from "mdb-react-ui-kit";
import CustomFileInput from "../../components/CustomFileInput";
import { ModalAlert, Layout } from "../../components";
import Map from "../../components/Map";
import { Loader } from "@googlemaps/js-api-loader";

const AddEvent = () => {
  const [showInputs, setShowInputs] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [haveCustomType, setHaveCustomType] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const navigate = useNavigate();

  const [formValue, setFormValue] = useState({
    locationName: "",
    locationSearch: "",
    title: "",
    types: [],
    custom_type: "",
    image: null,
    description: "",
    alert_distance: null,
    damage_level: null,
  });

  const [modalValue, setModalValue] = useState({
    modalTitle: "",
    modalMessage: "",
    showModal: false,
    onModalClose: () => {},
  });

  const loader =
    USE_GMAP_SDK && GOOGLE_MAPS_API_KEY
      ? new Loader({
          apiKey: GOOGLE_MAPS_API_KEY,
          version: "weekly",
          libraries: ["places"],
        })
      : null;

  const handleReverseSearch = async (latitude, longitude) => {
    if (USE_GEO_API && GOOGLE_MAPS_API_KEY) {
      try {
        const response_GeoReverse = await ApiHelper.postGeoReverse({
          latitude: latitude,
          longitude: longitude,
        });
        return response_GeoReverse.data?.formatted_address || "";
      } catch (error) {
        console.error("Error with reverse geocoding:", error);
        return "";
      }
    } else {
      try {
        const response = await fetch(
          `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
        );
        const data = await response.json();
        return data.display_name || "";
      } catch (error) {
        console.error("Error with reverse geocoding:", error);
        return "";
      }
    }
  };

  const handleSearch = async (searchText) => {
    if (USE_PLACE_API && GOOGLE_MAPS_API_KEY) {
      try {
        const response = await ApiHelper.postMapFindPlace({
          input: searchText,
        });
        let Place_Data_Array = response?.data?.predictions || [];
        setSearchResults(Place_Data_Array);
      } catch (error) {
        console.error("Error with place search:", error);
      }
    } else {
      try {
        const response = await fetch(
          `https://nominatim.openstreetmap.org/search?format=json&q=${searchText}`
        );
        const data = await response.json();
        setSearchResults(data);
      } catch (error) {
        console.error("Error with place search:", error);
      }
    }
  };

  const handleSelectLocation = async (placeIdOrLat, displayNameOrLon) => {
    if (USE_PLACE_API && GOOGLE_MAPS_API_KEY) {
      const response = await ApiHelper.postMapPlaceID({
        input: placeIdOrLat,
      });
      const { latitude, longitude } = response?.data;
      setSelectedPosition([latitude, longitude]);
      setSearchResults([]);
      setFormValue({ ...formValue, locationSearch: displayNameOrLon });
    } else {
      setSelectedPosition([placeIdOrLat, displayNameOrLon]);
      setSearchResults([]);
      setFormValue({ ...formValue, locationSearch: displayNameOrLon });
    }
  };

  const getMyCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        setSelectedPosition([latitude, longitude]);
        const address = await handleReverseSearch(latitude, longitude);
        setFormValue((prevFormValue) => ({
          ...prevFormValue,
          locationSearch: address,
        }));
      }, handleLocationError);
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  const handleLocationError = (error) => {
    const errorMessage = (error) => {
      switch (error.code) {
        case error.PERMISSION_DENIED:
          return "ผู้ใช้ปฏิเสธคำขอการเข้าถึงตำแหน่งที่ตั้ง";
        case error.POSITION_UNAVAILABLE:
          return "ข้อมูลตำแหน่งที่ตั้งไม่สามารถใช้งานได้";
        case error.TIMEOUT:
          return "การขอรับตำแหน่งที่ตั้งของผู้ใช้หมดเวลา";
        default:
          return "เกิดข้อผิดพลาดที่ไม่รู้จัก";
      }
    };
    setModalValue({
      modalTitle: "เกิดข้อผิดพลาด",
      modalMessage: errorMessage(error),
      showModal: true,
      onModalClose: () => {
        setModalValue({ ...modalValue, showModal: false });
        navigate("/alert-add");
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const {
      title,
      types,
      locationName,
      image,
      description,
      custom_type,
      alert_distance,
      damage_level,
    } = formValue;
    let imageId = null;

    if (
      selectedPosition &&
      title &&
      types.length > 0 &&
      !(haveCustomType && !custom_type)
    ) {
      if (image) {
        const form = new FormData();
        form.append("file", image);
        const { success: successImage, data: dataImage } =
          await ApiHelper.uploadFile(form);
        if (successImage) imageId = dataImage?.id;
      }

      setModalValue({
        modalTitle: "กำลังดำเนินการ",
        modalMessage: "กรุณารอสักครู่",
        showModal: true,
        onModalClose: () => {
          setModalValue({ ...modalValue, showModal: false });
        },
      });

      const { success } = await ApiHelper.addEventNotification({
        location_title: locationName,
        title,
        type: types,
        image: imageId,
        description,
        location: {
          coordinates: [
            parseFloat(selectedPosition[1]),
            parseFloat(selectedPosition[0]),
          ],
          type: "Point",
        },
        custom_type: types.includes("custom") ? custom_type : null,
        alert_distance,
        damage_level,
      });
      if (success) {
        setModalValue({
          modalTitle: "สำเร็จ",
          modalMessage: "เพิ่มการแจ้งเหตุเรียบร้อย",
          showModal: true,
          onModalClose: () => {
            setModalValue({ ...modalValue, showModal: false });
            navigate("/admin-alert");
          },
        });
      } else {
        setModalValue({
          modalTitle: "เกิดข้อผิดพลาด",
          modalMessage: DEFAULT_ERROR_MESSAGE,
          showModal: true,
          onModalClose: () => {
            setModalValue({ ...modalValue, showModal: false });
          },
        });
      }
    } else {
      setModalValue({
        modalTitle: "เกิดข้อผิดพลาด",
        modalMessage: "โปรดกรอกข้อมูลให้ครบถ้วน",
        showModal: true,
        onModalClose: () => {
          setModalValue({ ...modalValue, showModal: false });
        },
      });
    }
  };

  const handleTypeChange = (e) => {
    const { value, checked } = e.target;
    let newTypes = [...formValue.types];

    if (checked) {
      newTypes.push(value);
    } else {
      newTypes = newTypes.filter((type) => type !== value);
    }

    setHaveCustomType(newTypes.includes("custom"));
    setFormValue({ ...formValue, types: newTypes });
  };

  useEffect(() => {
    if (USE_GMAP_SDK && GOOGLE_MAPS_API_KEY) {
      loader.load().then(() => {
        console.log("Google Maps loaded");
      });
    }
  }, []);

  return (
    <Layout admin={true}>
      <MDBContainer
        fluid
        className="d-flex align-items-center justify-content-center min-vh-100"
        style={{ background: "linear-gradient(white, #84BBFF)" }}
      >
        <MDBCard
          className="m-5"
          style={{
            width: "1200px",
            minWidth: "350px",
            borderRadius: "20px",
          }}
        >
          <MDBCardBody className="px-sm-5 px-3">
            <h2
              className="text-uppercase text-center mb-5"
              style={{ color: "black", fontFamily: "Kanit-SemiBold" }}
            >
              เพิ่มการแจ้งเหตุ
            </h2>
            <div className="d-flex flex-column flex-lg-row">
              <div className="left-column">
                <div
                  style={{ marginBottom: "20px", fontFamily: "Kanit-Regular" }}
                >
                  <label>เรื่อง :</label>
                  <input
                    type="text"
                    onChange={(e) =>
                      setFormValue({ ...formValue, title: e.target.value })
                    }
                    placeholder="กรุณากรอกเรื่องที่ต้องการแจ้ง"
                    className="form-control w-100"
                  />
                </div>
                <p
                  style={{
                    marginBottom: "0",
                    fontSize: "1.2em",
                    fontFamily: "Kanit-Regular",
                  }}
                >
                  ประเภท (เลือกได้มากกว่า 1 ประเภท)
                  <span style={{ color: "red" }}>*</span>
                </p>
                <div className="row m-0 d-flex">
                  {[
                    { value: "life", label: "ชีวิตและร่างกาย", sizeColumn: 4 },
                    { value: "sex", label: "เพศ", sizeColumn: 2 },
                    { value: "drug", label: "ยาเสพติด", sizeColumn: 3 },
                    { value: "property", label: "ทรัพย์", sizeColumn: 3 },
                  ].map(({ value, label, sizeColumn }) => (
                    <label
                      key={value}
                      className={`form-check-label d-flex align-items-center col-6 col-md-${sizeColumn}`}
                      style={{ fontFamily: "Kanit-Regular" }}
                    >
                      <input
                        type="checkbox"
                        value={value}
                        onChange={handleTypeChange}
                        checked={formValue.types.includes(value)}
                        className="form-check-input me-2"
                      />
                      {label}
                    </label>
                  ))}
                </div>
                <div className="mb-4 d-flex align-items-center gap-2">
                  <label
                    className="form-check-label d-flex align-items-center"
                    style={{ fontFamily: "Kanit-Regular" }}
                  >
                    <input
                      type="checkbox"
                      value="custom"
                      onChange={handleTypeChange}
                      checked={formValue.types.includes("custom")}
                      className="form-check-input me-2"
                    />
                    อื่นๆ
                  </label>
                  {haveCustomType && (
                    <input
                      type="text"
                      placeholder="โปรดระบุ"
                      value={formValue.custom_type || ""}
                      onChange={(e) =>
                        setFormValue({
                          ...formValue,
                          custom_type: e.target.value,
                        })
                      }
                      className="form-control w-100"
                    />
                  )}
                </div>
                <div
                  style={{ marginBottom: "20px", fontFamily: "Kanit-Regular" }}
                >
                  <label>คำอธิบาย :</label>
                  <input
                    type="text"
                    onChange={(e) =>
                      setFormValue({
                        ...formValue,
                        description: e.target.value,
                      })
                    }
                    placeholder="กรุณากรอกคำอธิบาย"
                    className="form-control w-100"
                  />
                </div>
                <div
                  style={{ marginBottom: "20px", fontFamily: "Kanit-Regular" }}
                >
                  <label>ระดับความรุนแรง :</label>
                  <select
                    className="py-1 px-2 text-gray-900 border border-#bdbdbd rounded transition-all duration-100 w-100"
                    onChange={(e) =>
                      setFormValue({
                        ...formValue,
                        damage_level: e.target.value,
                      })
                    }
                    value={formValue.damage_level || ""}
                  >
                    <option value="" disabled selected hidden>
                      กรุณาเลือกระดับ
                    </option>
                    <option value={1}>ต่ำมาก</option>
                    <option value={2}>ต่ำ</option>
                    <option value={3}>ปานกลาง</option>
                    <option value={4}>สูง</option>
                    <option value={5}>สูงมาก</option>
                  </select>
                </div>
                <div
                  style={{ marginBottom: "20px", fontFamily: "Kanit-Regular" }}
                >
                  <label>รัศมีการส่งแจ้งเตือนจากจุดเกิดเหตุ (กม.)</label>
                  <input
                    type="number"
                    placeholder="กรุณาระบุขนาดรัศมีการแจ้งเตือน"
                    className="form-control w-100"
                    value={formValue.alert_distance || ""}
                    onChange={(e) =>
                      setFormValue({
                        ...formValue,
                        alert_distance: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="right-column p-0 ps-lg-3">
                <div
                  style={{ marginBottom: "20px", fontFamily: "Kanit-Regular" }}
                >
                  <label>ชื่อสถานที่ :</label>
                  <input
                    type="text"
                    value={formValue.locationName}
                    onChange={(e) =>
                      setFormValue({
                        ...formValue,
                        locationName: e.target.value,
                      })
                    }
                    placeholder="กรุณากรอกชื่อสถานที่"
                    className="form-control mb-2 w-100"
                  />
                </div>
                <div className="d-flex align-items-center position-relative">
                  <div className="input-group input-group-lg flex-grow-1 position-relative">
                    <input
                      placeholder="กรุณากรอกที่อยู่ที่เกิดเหตุ"
                      size="lg"
                      id="input-location"
                      type="text"
                      name="location"
                      value={formValue.locationSearch || ""}
                      onChange={(e) =>
                        setFormValue({
                          ...formValue,
                          locationSearch: e.target.value,
                        })
                      }
                      required
                      className="form-control w-100"
                      style={{
                        fontFamily: "Kanit-Regular",
                        paddingRight: "50px",
                        width: "100%",
                        zIndex: 1,
                      }}
                    />
                    <button
                      onClick={() => handleSearch(formValue.locationSearch)}
                      className="btn position-absolute"
                      style={{
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                        background: "none",
                        border: "none",
                        padding: 0,
                        boxShadow: "none",
                      }}
                    >
                      <img
                        src="search.png"
                        alt="Search"
                        style={{ height: "30px" }}
                      />
                    </button>
                  </div>
                  <span
                    style={{
                      marginLeft: "10px",
                      cursor: "pointer",
                    }}
                    onClick={getMyCurrentLocation}
                  >
                    <img
                      src="current-location-icon.png"
                      alt="pin"
                      style={{
                        height: "30px",
                      }}
                    />
                  </span>
                </div>
                {searchResults.length > 0 && (
                  <div
                    className="dropdown-menu show w-35 mt-1"
                    style={{
                      zIndex: 10000,
                      fontFamily: "Kanit-Regular",
                    }}
                  >
                    {searchResults.map((result) => (
                      <button
                        key={USE_PLACE_API ? result.place_id : result.place_id}
                        className="dropdown-item"
                        onClick={() =>
                          USE_PLACE_API
                            ? handleSelectLocation(
                                result.place_id,
                                result.description
                              )
                            : handleSelectLocation(
                                result.lat,
                                result.lon,
                                result.display_name
                              )
                        }
                        style={{
                          maxWidth: "400px",
                          whiteSpace: "normal",
                          wordWrap: "break-word",
                        }}
                      >
                        {USE_PLACE_API
                          ? result.description
                          : result.display_name}
                      </button>
                    ))}
                  </div>
                )}
                <div className="mt-3" style={{ position: "relative" }}>
                  <Map
                    position={selectedPosition}
                    setPosition={setSelectedPosition}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex flex-column align-items-center w-100 mb-3">
              <div className="d-flex justify-content-center w-100">
                <button
                  onClick={() => setShowInputs(!showInputs)}
                  className="btn my-3"
                  style={{
                    backgroundColor: "#5E8FCB",
                    color: "white",
                    fontFamily: "Kanit-Regular",
                    fontSize: "1rem",
                    width: "100%",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "8px 16px",
                  }}
                >
                  <span style={{ flex: 1, textAlign: "center" }}>
                    หลักฐานประกอบ
                  </span>
                  <img
                    src="arrow.png"
                    alt={showInputs ? "Hide" : "Show"}
                    style={{ height: "16px", marginLeft: "8px" }}
                  />
                </button>
              </div>

              {showInputs && (
                <div className="d-flex flex-column align-items-center w-100 gap-3">
                  <div className="w-100" style={{ maxWidth: "900px" }}>
                    <CustomFileInput
                      setSelectedFile={(file) =>
                        setFormValue({ ...formValue, image: file })
                      }
                    />
                  </div>
                </div>
              )}
            </div>

            <MDBBtn
              type="submit"
              className="btn btn-lg w-100 rounded-6 mt-4"
              style={{
                backgroundColor: "#B62319",
                borderColor: "#B62319",
                boxShadow: "none",
                fontFamily: "Kanit-Regular",
                height: "40px",
              }}
              onClick={handleSubmit}
            >
              บันทึก
            </MDBBtn>
          </MDBCardBody>
        </MDBCard>
        <ModalAlert
          show={modalValue.showModal}
          setShow={(show) => (show ? null : modalValue.onModalClose())}
          title={modalValue.modalTitle}
          onClose={modalValue.onModalClose}
        >
          {modalValue.modalMessage}
        </ModalAlert>
      </MDBContainer>
      <style jsx>{`
        .left-column,
        .right-column {
          flex: 1;
          min-width: 300px;
        }
      `}</style>
    </Layout>
  );
};

export default AddEvent;
