import { useEffect, useState } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";
import { usePrevious } from "../../libs/hooks";
import { ApiHelper } from "../../libs/api";
import { Layout } from "../../components";
import dayjs from "dayjs";

import {
  MDBBtn,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBValidation,
  MDBInput,
} from "mdb-react-ui-kit";

const Planning = () => {

  const navigate = useNavigate();
  const [addressOptions, setAddressOptions] = useState({
    regions: [],
    provinces: [],
    districts: [],
    tambons: [],
  });

  const [formValue, setFormValue] = useState({
    region: "",
    province: "",
    district: "",
    tambon: "",
    date: dayjs().format("YYYY-MM-DD"),
    time: dayjs().format("HH:mm"),
  });

  const prevFormValue = usePrevious(formValue);

  const onChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { region, province, district, tambon, date, time } = formValue;
    if (region && province && district && tambon && date && time) {
      navigate({
        pathname: "risk",
        search: createSearchParams(formValue).toString(),
      });
    }
  };


  useEffect(() => {
    const { region, province, district } = formValue;
    if (prevFormValue && prevFormValue.region !== region) {
      if (region) {
        ApiHelper.getProvinces(region).then(({ success, data }) => {
          if (success) {
            const provinces = data.map(({ province_th }) => province_th);
            setAddressOptions({ ...addressOptions, provinces });
          }
        });
      }
      setFormValue({ ...formValue, province: "", district: "", tambon: "" });
    }
    if (prevFormValue && prevFormValue.province !== province) {
      if (province) {
        ApiHelper.getDistricts(province).then(({ success, data }) => {
          if (success) {
            const districts = data.map(
              ({ district_th_short }) => district_th_short
            );
            setAddressOptions({ ...addressOptions, districts });
          }
        });
      }
      setFormValue({ ...formValue, district: "", tambon: "" });
    }
    if (prevFormValue && prevFormValue.district !== district) {
      if (district) {
        ApiHelper.getTambons(district).then(({ success, data }) => {
          if (success) {
            const tambons = data.map(
              ({ tambon_th_short }) => tambon_th_short
            );
            setAddressOptions({ ...addressOptions, tambons });
          }
        });
      }
      setFormValue({ ...formValue, tambon: "" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValue]);

  useEffect(() => {
    ApiHelper.getRegions().then(({ success, data }) => {
      if (success) {
        const regions = data.map(({ region }) => region);
        setAddressOptions({ ...addressOptions, regions });
      }
    });
  }, [])

  return (
    <Layout>
      <MDBContainer className="d-flex align-items-center justify-content-center">
        <MDBCard className="mx-4 my-5 rounded-7" style={{ width: "500px" }}>
          <MDBCardBody className="px-3 px-md-5">
            <h2 className="text-uppercase my-2 fw-bold">วางแผนการเดินทาง</h2>
            <h6 className="mb-4">
              โปรดกรอกข้อมูลพื้นที่ที่ต้องการตรวจสอบความเสี่ยง
            </h6>
            <MDBValidation onSubmit={handleSubmit}>
              <h5 className="mb-3 fw-bold">สถานที่</h5>
              <h6 className="mb-1">
                ภาค <span className="text-danger">*</span>
              </h6>
              <select
                className="form-select mb-4"
                aria-label="input-region"
                id="input-region"
                name="region"
                value={formValue.region}
                onChange={onChange}
                required
              >
                <option value="">โปรดระบุภาค</option>
                {addressOptions?.regions.length &&
                  addressOptions.regions.map((region, idx) => (
                    <option key={`${region}-${idx}`} value={region}>
                      {region}
                    </option>
                  ))}
              </select>
              <h6 className="mb-1">
                จังหวัด <span className="text-danger">*</span>
              </h6>
              <select
                className="form-select mb-4"
                aria-label="input-province"
                id="input-province"
                name="province"
                value={formValue.province}
                onChange={onChange}
                required
                disabled={!formValue.region}
              >
                <option value="">โปรดระบุจังหวัด</option>
                {addressOptions?.provinces.length &&
                  addressOptions.provinces.map((province, idx) => (
                    <option key={`${province}-${idx}`} value={province}>
                      {province}
                    </option>
                  ))}
              </select>
              <h6 className="mb-1">
                เขต / อำเภอ <span className="text-danger">*</span>
              </h6>
              <select
                className="form-select mb-4"
                aria-label="input-district"
                id="input-district"
                name="district"
                value={formValue.district}
                onChange={onChange}
                required
                disabled={!formValue.province}
              >
                <option value="">โปรดระบุเขต / อำเภอ</option>
                {addressOptions?.districts.length &&
                  addressOptions.districts.map((district, idx) => (
                    <option key={`${district}-${idx}`} value={district}>
                      {district}
                    </option>
                  ))}
              </select>
              <h6 className="mb-1">
                แขวง / ตำบล <span className="text-danger">*</span>
              </h6>
              <select
                className="form-select mb-4"
                aria-label="input-tambon"
                id="input-tambon"
                name="tambon"
                value={formValue.tambon}
                onChange={onChange}
                required
                disabled={!formValue.district}
              >
                <option value="">โปรดระบุแขวง / ตำบล</option>
                {addressOptions?.tambons.length &&
                  addressOptions.tambons.map((tambon, idx) => (
                    <option key={`${tambon}-${idx}`} value={tambon}>
                      {tambon}
                    </option>
                  ))}
              </select>
              <h5 className="mb-4 fw-bold">วันและเวลา</h5>
              <MDBInput
                wrapperClass="mb-4"
                label="วันที่"
                size="lg"
                id="input-date"
                type="date"
                name="date"
                value={formValue.date}
                onChange={onChange}
                min={new Date().toISOString().split("T")[0]}
                max={new Date(new Date().setDate(new Date().getDate() + 6)).toISOString().split("T")[0]} 
                required
              />
              <MDBInput
                wrapperClass="mb-4"
                label="เวลา"
                size="lg"
                id="input-time"
                type="time"
                name="time"
                value={formValue.time}
                onChange={onChange}
                required
              />
              <MDBBtn
                type="submit"
                color="danger"
                className="mb-4 w-100"
                size="lg"
              >
                ตรวจสอบ
              </MDBBtn>
            </MDBValidation>
          </MDBCardBody>
        </MDBCard>
      </MDBContainer>
    </Layout>
  );
};

export default Planning;
