import { createContext, useState } from "react";

export const RiskContext = createContext();

export const RiskProvider = ({ children }) => {
  const [riskInfo, setRiskInfo] = useState(null);
  const [riskReport, setRiskReport] = useState(null);
  const [showModalReport, setShowModalReport] = useState(false);

  return (
    <RiskContext.Provider
      value={{
        riskInfo,
        setRiskInfo,
        riskReport,
        setRiskReport,
        showModalReport,
        setShowModalReport,
      }}
    >
      {children}
    </RiskContext.Provider>
  );
};
