import React, { useEffect } from "react";
import { USE_GMAP_SDK, GOOGLE_MAPS_API_KEY } from "../libs/constants";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import {
  MapContainer,
  TileLayer,
  Marker as OSMMarker,
  useMap,
  useMapEvents,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

const DEFAULT_COORDINATE = { lat: 13.736717, lng: 100.523186 };
const containerStyle = { width: "100%", height: "300px" };

let DefaultIcon = L.icon({
  iconUrl: "location-pin.png",
  iconSize: [32, 32],
  iconAnchor: [16, 32],
});
L.Marker.prototype.options.icon = DefaultIcon;

const LocationMarker = ({ position, setPosition }) => {
  const map = useMap();

  useEffect(() => {
    if (position) {
      map.flyTo(position, map.getZoom(), { animate: true, duration: 1.5 });
    }
  }, [position, map]);

  useMapEvents({
    click(e) {
      setPosition([e.latlng.lat, e.latlng.lng]);
    },
  });

  return position === null ? null : <OSMMarker position={position}></OSMMarker>;
};

const PinMarker = ({ position }) => {
  const map = useMap();

  useEffect(() => {
    if (position) {
      map.flyTo(position, map.getZoom(), { animate: true, duration: 1.5 });
    }
  }, [position, map]);

  return position === null ? null : <OSMMarker position={position}></OSMMarker>;
};

const GoogleMapsComponent = ({ position, setPosition, fixPosition }) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  });

  const handleMapClick = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setPosition([lat, lng]);
  };

  useEffect(() => {
    if (isLoaded && position) {
      console.log("Map loaded and centered to:", position);
    }
  }, [isLoaded, position]);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={
        position ? { lat: position[0], lng: position[1] } : DEFAULT_COORDINATE
      }
      zoom={16}
      onClick={fixPosition ? undefined : handleMapClick}
    >
      {position && <Marker position={{ lat: position[0], lng: position[1] }} />}
    </GoogleMap>
  ) : null;
};

const OpenStreetMapComponent = ({ position, setPosition, fixPosition }) => {
  return (
    <MapContainer
      center={position || DEFAULT_COORDINATE}
      zoom={16}
      style={{ height: "300px" }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {!fixPosition ? (
        <LocationMarker position={position} setPosition={setPosition} />
      ) : (
        <PinMarker position={position || DEFAULT_COORDINATE} />
      )}
    </MapContainer>
  );
};

const Map = ({ position, setPosition, fixPosition = false }) => {
  return USE_GMAP_SDK ? (
    <GoogleMapsComponent
      position={position}
      setPosition={setPosition}
      fixPosition={fixPosition}
    />
  ) : (
    <OpenStreetMapComponent
      position={position}
      setPosition={setPosition}
      fixPosition={fixPosition}
    />
  );
};

export default Map;
