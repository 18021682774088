import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { nanoid } from "nanoid";
import { API_URL, REDIRECT_URI_APPLICATION_APK } from "../../libs/constants";
import { useAuth } from "../../auth/AuthContext";

const LineLogin = () => {
  const [deepLinkUrl, setDeepLinkUrl] = useState("");
  const { setToken, refreshToken } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");
      if (code === null) {
        const currentUrl = decodeURIComponent(window.location.href);
        const platform = new URL(currentUrl).searchParams.get("platform");
        if (platform) {
          sessionStorage.setItem("platform", platform);
        }
        const state = nanoid();

        const respond_web = await axios.post(`${API_URL}/line/login`, {
          state: state,
        });
        const redirectUrl = respond_web.data.url;
        const redirectUrl_decode = decodeURIComponent(redirectUrl);
        const state_URL = new URL(redirectUrl_decode).searchParams.get("state");
        if (redirectUrl && state === state_URL) {
          window.location.href = redirectUrl;
        } else {
          /*  console.log("Failed to get the redirect URL."); */
        }
      } else if (code && code !== "No_trigger") {
        const respond_web_verify = await axios.post(`${API_URL}/line/token`, {
          code: code,
        });
        const platform_before_direct = sessionStorage.getItem("platform");
        if (platform_before_direct === "web") {
          const accessToken = respond_web_verify?.data.data.accessToken;
          const refresh_token = respond_web_verify?.data.data.refreshToken;
          if (accessToken && refresh_token) {
            try {
              const data = await refreshToken(refresh_token);
              await setToken(data.access_token, data.refresh_token);
              navigate("/");
            } catch (err) {
              console.log("Error setting line login");
              navigate("/");
            }
          } else {
            /* console.log("Failed to get tokens from backend."); */
          }
        } else {
          const accessToken = respond_web_verify?.data.data.accessToken;
          const refreshToken = respond_web_verify?.data.data.refreshToken;
          if (accessToken && refreshToken) {
            const deepLink = `${REDIRECT_URI_APPLICATION_APK}?access_token=${accessToken}&refresh_token=${refreshToken}&form_login_method=line-login`;
            setDeepLinkUrl(deepLink);
            window.history.replaceState(
              null,
              null,
              "/login-line?code=No_trigger"
            );
          } else {
            /* console.log("Failed to get tokens from backend."); */
          }
        }
      }
    };
    fetchData();
  },[]);
  useEffect(() => {
    if (deepLinkUrl) {
      window.location.replace(deepLinkUrl);
    }
  }, [deepLinkUrl]);

  return <div>{deepLinkUrl && <a href={deepLinkUrl}>deeplink</a>}</div>;
};

export default LineLogin;
