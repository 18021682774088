import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "./AuthContext";
import { Loading } from "../components";

export const ProtectedRoute = ({
  requireLogin = true,
  requireEmailVerification = false,
  requireAcceptedTerms = false,
  requireVerifiedIdentity = false,
  requireAdmin = false,
}) => {
  const {
    isAuthenticated,
    isVerifiedEmail,
    hasAcceptedTerms,
    isIdentityVerified,
    isAdmin,
    loading,
  } = useAuth();

  if (loading) {
    return <Loading />
  }

  if (isAdmin) {
    return <Outlet />;
  }

  if (requireLogin && !isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  if (requireEmailVerification && !isVerifiedEmail) {
    return <Navigate to="/verify-email" replace />;
  }

  if (requireAcceptedTerms && !hasAcceptedTerms) {
    return <Navigate to="/accept-term" replace />;
  }

  if (requireVerifiedIdentity && !isIdentityVerified) {
    return <Navigate to="/verify-identity" replace />;
  }

  if (requireAdmin && !isAdmin) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};
