const Loading = ({size = "md"}) => {
  const sizeLoad = {
    "sm": "2rem",
    "md": "3rem"
  }
  return (
    <div className="d-flex h-100 align-items-center justify-content-center">
      <div
        className="spinner-border text-primary"
        style={{ width: sizeLoad[size], height: sizeLoad[size] }}
      />
    </div>
  );
};

export default Loading;
