import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { AuthHelper } from "../../libs/auth";
import { Layout, Loading } from "../../components";

import {
  MDBBtn,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardFooter,
  MDBCardHeader,
  MDBCardTitle,
  MDBIcon,
} from "mdb-react-ui-kit";
import { PEOPLE_ROLE } from "../../libs/constants";
import { useAuth } from "../../auth/AuthContext";
import { convertToThaiDate, formatIdentityNumber } from "../../libs/function";
import { mappingSex } from "../../libs/mappingValue";

const Profile = () => {
  const { logout, isIdentityVerified, isAdmin, adminMode, changeAdminMode } =
    useAuth();
  const navigate = useNavigate();
  const [loading, setLoaing] = useState(true);
  const [userProfile, setuserProfile] = useState({
    email: "",
    name: "",
    surname: "",
    nationalId: "",
    birthday: "",
    gender: "",
    verified: false,
    role: "",
  });

  const handleLogout = async (e) => {
    e.preventDefault();
    await logout();
    navigate("/");
  };

  const getUserProfile = async () => {
    try {
      const { success: successMe, data: info } = await AuthHelper.me();
      if (successMe) {
        const { first_name, last_name, email, role } = info;
        setuserProfile((prevProfile) => ({
          ...prevProfile,
          name: first_name,
          surname: last_name,
          email: email,
          verified: isIdentityVerified,
          role: role,
        }));
      }
      const { success: successProfile, data: profile } =
        await AuthHelper.getUserProfile();
      if (successProfile && profile.length > 0) {
        const { national_id, birthday, gender } = profile[0];
        setuserProfile((prevProfile) => ({
          ...prevProfile,
          nationalId: national_id,
          birthday: birthday,
          gender: gender,
        }));
      }
    } catch (error) {
      console.error("Error fetching user profile:", error);
    } finally {
      setLoaing(false);
    }
  };

  useEffect(() => {
    getUserProfile();
  }, []);

  return (
    <Layout admin={adminMode}>
      {loading ? ( <Loading /> ) : (
        <MDBContainer className="d-flex align-items-center justify-content-center">
          <MDBCard
            className="my-5 mx-1 rounded-7"
            style={{ maxWidth: "600px" }}
          >
            <MDBCardHeader>
              <MDBCardTitle className="text-uppercase text-left mt-1">
                ข้อมูลส่วนตัว
              </MDBCardTitle>
            </MDBCardHeader>
            <div
              className="w-100 p-2 d-flex flex-wrap position-relative align-items-center "
              style={{ backgroundColor: "#E0E0E0" }}
            >
              <div className="m-2 mx-3">
                <MDBIcon fas icon="user-circle" size="3x" />
              </div>
              <div className="m-1 ms-2 overflow-auto me-4">
                <h5>
                  {userProfile.name} {userProfile.surname}
                </h5>
                <h6>{userProfile.email}</h6>
              </div>
            </div>
            <MDBCardBody>
              {userProfile.verified  ? (
                <div>
                  <h6>
                    <b>เพศ: </b>
                    {mappingSex(userProfile.gender)}
                  </h6>
                  <h6>
                    <b>วัน/เดือน/ปี เกิด: </b>
                    {userProfile.birthday ? convertToThaiDate(userProfile.birthday, false): "ไม่ระบุ"}
                  </h6>
                  <h6>
                    <b>รหัสบัตรประชาชน: </b>
                    {userProfile.nationalId ? formatIdentityNumber(userProfile.nationalId) : "ไม่ระบุ"}
                  </h6>
                </div>
              ) : (
                <Link to="/verify-identity">
                  <MDBBtn
                    type="button"
                    className="mb-2 w-100"
                    color="warning"
                    size="lg"
                  >
                    ยืนยันตัวตน
                  </MDBBtn>
                </Link>
              )}
              {userProfile.role.toLowerCase() !== PEOPLE_ROLE.toLowerCase() && userProfile.verified && (
                <Link
                  to={adminMode ? "/" : "/admin-report"}
                  onClick={() => changeAdminMode(!adminMode)}
                >
                  <MDBBtn
                    type="button"
                    className="mb-2 w-100"
                    color="primary"
                    size="lg"
                  >
                    สลับบทบาท
                  </MDBBtn>
                </Link>
              )}
              <Link to="/change-password">
                <MDBBtn
                  type="button"
                  className="mb-2 w-100"
                  color="secondary"
                  size="lg"
                >
                  เปลี่ยนรหัสผ่าน
                </MDBBtn>
              </Link>
              <Link to="/delete-account">
                <MDBBtn
                  type="button"
                  className="mb-2 w-100"
                  color="secondary"
                  size="lg"
                >
                  ลบบัญชีผู้ใช้งาน
                </MDBBtn>
              </Link>

              <p className="text-center m-0">
                <Link to="/privacy">
                  นโยบายความเป็นส่วนตัว (Privacy Policy Notice)
                </Link>
              </p>
            </MDBCardBody>
            <MDBCardFooter>
              <MDBBtn
                type="button"
                className="mb-2 w-100"
                color="danger"
                size="lg"
                onClick={handleLogout}
              >
                ออกจากระบบ
              </MDBBtn>
            </MDBCardFooter>
          </MDBCard>
        </MDBContainer>
      )}
    </Layout>
  );
};

export default Profile;
