import { directus } from "./directus";
import { PEOPLE_ROLE } from "./constants";
import { ApiUtils } from "./api";
import { createUser, logout, passwordRequest, passwordReset, readItems, readMe } from '@directus/sdk';


export const AuthHelper = {
  login: (email, password) => ApiUtils.call(() => directus.login(email, password)),
  me: () => ApiUtils.call(() => directus.request(readMe())),
  setToken: async (token) => {
    await directus.setToken(token);
  },
  getToken: () => {
    return directus.getToken();
  },
  logout: (refresh_token) => ApiUtils.call(() => directus.request(logout(refresh_token, "json"))),
  register: (email, password) =>
    ApiUtils.call(() =>
      directus.request(createUser({
        email,
        password,
        role: PEOPLE_ROLE,
      }))
    ),
  resetPassword: (reset_token, password) =>
    ApiUtils.call(() => directus.request(passwordReset(reset_token, password))),
  forgotPassword: (email) => directus.request(passwordRequest(email)),
  getUserProfile: (userId) => ApiUtils.call(() => directus.request(readItems("user_profiles", {filter: { user_id: { _eq: userId } }}))),
};
