import React, { useState } from "react";
import { AudioRecorder } from "react-audio-voice-recorder";

const CustomAudioRecorder = ({
  text,
  setText,
  placeholder,
  onlyFile=false,
  setAudio,
}) => {
  const [audioUrl, setAudioUrl] = useState(null);
  const [isRecording, setIsRecording] = useState(false);

  const handleRecordingComplete = (audioBlob) => {
    const audioUrl = URL.createObjectURL(audioBlob);
    setAudioUrl(audioUrl);
    setAudio(audioBlob);
    setIsRecording(false);
  };

  const handleAudioFile = (e) => {
    const file = e.target.files[0];
    const audioUrl = URL.createObjectURL(file);
    setAudioUrl(audioUrl);
    setAudio(file);
  };

  return (
    <>
      <div className="d-flex flex-row justify-content-between align-items-center w-100 gap-3 py-1 px-2 text-base border border-gray-300 rounded">
        <AudioRecorder
          onRecordingComplete={handleRecordingComplete}
          audioTrackConstraints={{ noiseSuppression: true }}
          downloadOnSavePress={false}
          downloadFileExtension="wav"
          mimeType="audio/wav"
          onClick={() => setIsRecording(!isRecording)}
        />
        {onlyFile ? (
          <div className="audio-file">
          <input
            type="file"
            accept="audio/*"
            id="audioFileInput"
            className="d-none"
            onChange={handleAudioFile}
          />
          <label htmlFor="audioFileInput" style={{color: "#a0a0a0"}}>
            {placeholder}
          </label>
          </div>
        ) : (
          <input
            type="text"
            value={text}
            onChange={setText}
            placeholder={placeholder}
            className="text-input"
          />
        )}
      </div>

      {audioUrl && (
        <div style={{ marginTop: "10px" }}>
          <audio className="w-100 ml-4" controls src={audioUrl}></audio>
        </div>
      )}
      <style jsx>{`
        .text-input {
          width: 100%;
          border: none;
          outline: none;
          font-size: 1em;
          color: #000;
          background: transparent;
          text-align: left;
        }
        .audio-file {
          width: 100%;
          cursor: pointer;
          text-align: end;
        }
        .recording {
          width: 14rem;
        }
      `}</style>
    </>
  );
};

export default CustomAudioRecorder;
