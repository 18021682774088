import { useState, useEffect } from 'react';
import dayjs from 'dayjs';

import { RiskCard } from '.';

const RiskList = ({ data, date, onClick = null }) => {
  const [fillActive, setFillActive] = useState(date);
  const [crimeRisks, setCrimeRisks] = useState({});
  const MAX_DAYS = 7;

  const handleFillClick = (value) => {
    if (value === fillActive) {
      return;
    }
    if (onClick) onClick(value);
    setFillActive(value);
  };

  useEffect(() => {
    if (data) {
      setCrimeRisks(data);
    } else {
      // init crime risk
      const risks = {};
      for (let i = 0; i < MAX_DAYS; i++) {
        const d = date ? dayjs(date).add(i, 'd') : dayjs().add(i, 'd');
        const df = d.format('YYYY-MM-DD');
        risks[df] = {};
        for (let j = 0; j < 24; j++) {
          const t = d.hour(j).minute(0);
          const tf = t.format('HH:mm');
          risks[df][tf] = {
            crime_level: null,
            description: null,
            prob: null,
          };
        }
      }
      setCrimeRisks(risks);
    }
    setFillActive(date || dayjs().format('YYYY-MM-DD'));
  }, [data, date]);

  const setRisk = (level) => {
    switch (level) {
      case 1:
        return {
          info: 'ต่ำมาก',
          bgColor: '#00C853',
          textColor: 'black',
          icon: 'laugh-beam',
        };
      case 2:
        return {
          info: 'ต่ำ',
          bgColor: '#AEEA00',
          textColor: 'black',
          icon: 'smile',
        };
      case 3:
        return {
          info: 'ปานกลาง',
          bgColor: '#FFD600',
          textColor: 'black',
          icon: 'meh',
        };
      case 4:
        return {
          info: 'สูง',
          bgColor: '#FF6D00',
          textColor: 'white',
          icon: 'frown',
        };
      case 5:
        return {
          info: 'สูงมาก',
          bgColor: '#D50000',
          textColor: 'white',
          icon: 'tired',
        };
      default:
        return {
          info: 'ไม่มีข้อมูล',
          bgColor: '#E0E0E0',
          textColor: 'black',
          icon: 'question-circle',
        };
    }
  };

  const Pagination = ({ data }) => {
    return (
      <div className='d-flex justify-content-center flex-wrap'>
        {Object.keys(data).map((tf, idx) => {
          return (
            <div
              key={idx.toString()}
              className='align-self-end position-relative d-flex flex-row flex-grow-1'
            >
              {idx % 6 === 0 && (
                <div
                  style={{
                    paddingBottom: '1.5rem',
                    paddingLeft: '0rem',
                    fontSize: '0.8rem',
                  }}
                >
                  <span className='position-absolute'>|{tf}</span>
                </div>
              )}

              <div
                style={{
                  width: '0.5rem',
                  height: '0.3rem',
                  margin: '0.05rem',
                  flex: '1 1 0',
                  alignSelf: 'end',
                  backgroundColor: setRisk(data[tf].crime_level).bgColor,
                }}
              />
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div
      className='d-flex flex-column overflow-auto'
      style={{ maxHeight: '400px' }}
    >
      {Object.keys(crimeRisks).map((df, idx) => {
        if (idx < MAX_DAYS) {
          return (
            <div
              key={df}
              className={`bg-white mb-1 p-2 pb-1 rounded-6 shadow-2-strong d-flex flex-column ${
                fillActive === df && 'opacity-25'
              }`}
              style={{ minWidth: '10rem', cursor: 'pointer' }}
              onClick={() => handleFillClick(df)}
            >
              {(() => {
                const maxRiskTime = Object.keys(crimeRisks[df]).reduce(
                  (maxTime, currentTime) => {
                    const maxLevel = crimeRisks[df][maxTime]?.crime_level ?? 0;
                    const currentLevel =
                      crimeRisks[df][currentTime]?.crime_level ?? 0;
                    return currentLevel > maxLevel ? currentTime : maxTime;
                  },
                  Object.keys(crimeRisks[df])[0]
                );
                const maxRisk = crimeRisks[df][maxRiskTime];

                return (
                  <div className='d-flex flex-column justify-content-center'>
                    <RiskCard
                      date={df}
                      width='100%'
                      maxTime={maxRiskTime}
                      maxLevel={maxRisk?.crime_level}
                      description={maxRisk?.description}
                      prob={maxRisk?.prob}
                      showDescription={false}
                    />
                  </div>
                );
              })()}

              <Pagination data={crimeRisks[df]} />
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};
export default RiskList;
