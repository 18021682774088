import { MDBBtn, MDBContainer, MDBCard, MDBCardBody } from "mdb-react-ui-kit";
import { ModalAlert, Layout, Loading } from "../../components";
import Map from "../../components/Map";

import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { ApiHelper } from "../../libs/api";
import { mappingTypeValue } from "../../libs/mappingValue";
import { convertToThaiDate } from "../../libs/function";
import { DEFAULT_ERROR_MESSAGE, API_URL } from "../../libs/constants";
import { useAuth } from "../../auth/AuthContext";

const ReportDetail = () => {
  const { userId } = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const eventId = searchParams.get("id");
  const [loading, setLoaing] = useState(true);

  const [showInputs, setShowInputs] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState(null);

  const ShowData = ({ title, content }) => {
    return (
      <div className="d-flex flex-row gap-2">
        <h5>{title} </h5>
        <p>{content}</p>
      </div>
    );
  };

  const [reportValue, setReportValue] = useState({
    location_tilte: "",
    title: "",
    type: null,
    custom_type: null,
    image: null,
    video: null,
    audio_title: null,
    audio_description: null,
    audio_other: null,
    description: "",
    reporter: "",
    reportTime: "",
    region: "",
    province: "",
    district: "",
    tambon: "",
    confirm: false,
  });

  const [formValue, setFormValue] = useState({
    level: null,
    distant: null,
    confirm: null,
    notification: false,
  });

  const [modalValue, setModalValue] = useState({
    modalTitle: "",
    modalMessage: "",
    showModal: false,
    onModalClose: () => {},
  });

  const handleError = (message) => {
    setModalValue({
      modalTitle: "เกิดข้อผิดพลาด",
      modalMessage: message,
      showModal: true,
      onModalClose: () => {
        setModalValue({ ...modalValue, showModal: false });
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { confirm, level, distant, notification } = formValue;
    const submitValue = async () => {
      setModalValue({
        modalTitle: "กำลังดำเนินการ",
        modalMessage: "กรุณารอสักครู่",
        showModal: true,
        onModalClose: () => {
          setModalValue({ ...modalValue, showModal: false });
        },
      });
      const { success } = await ApiHelper.updateEvent(eventId, {
        user_id: userId,
        damage_level: level,
        alert_distance: distant,
        send_notification: notification,
        verified_by_staff: confirm,
      });
      if (success) {
        setModalValue({
          modalTitle: "สำเร็จ",
          modalMessage: "บันทึกการประเมินของผู้ดูแลเรียบร้อยแล้ว",
          showModal: true,
          onModalClose: () => {
            setModalValue({ ...modalValue, showModal: false });
            navigate("/admin-report");
          },
        });
      } else {
        handleError(DEFAULT_ERROR_MESSAGE);
      }
    };

    if (confirm === false) {
      submitValue(confirm, level, distant, notification);
      return;
    }
    if (!confirm) {
      handleError("กรุณากรอกข้อมูลให้ครบถ้วน");
      return;
    }
    if (!level) {
      handleError("กรุณากรอกข้อมูลให้ครบถ้วน");
      return;
    }
    if (notification && !distant) {
      handleError("กรุณากรอกข้อมูลให้ครบถ้วน");
      return;
    }
    submitValue();
  };

  useEffect(() => {
    const fetchReport = async () => {
      try {
        const { success, data: reportData } = await ApiHelper.getEventById(
          eventId
        );
        if (success) {
          setSelectedPosition([
            reportData.location?.coordinates?.[1],
            reportData.location?.coordinates?.[0],
          ]);

          let name;
          if (reportData.user_id) {
            const { success, data: userData } = await ApiHelper.getUser(
              reportData.user_id
            );
            if (success) {
              if (userData.first_name && userData.last_name)
                name = `${userData.first_name} ${userData.last_name}`;
              else if (userData.email) name = userData.email;
            }
          }
          setReportValue({
            ...reportValue,
            location_title: reportData.location_title || "",
            title: reportData.title || "",
            type: reportData.type || "",
            custom_type: reportData.custom_type || null,
            image: reportData.image || null,
            video: reportData.video || null,
            audio_title: reportData.audio_title || null,
            audio_description: reportData.audio_description || null,
            audio_other: reportData.audio_other || null,
            description: reportData.description || "",
            reporter: name || "",
            reportTime: reportData.date_created || "",
            region: reportData.region || "",
            province: reportData.province || "",
            district: reportData.district || "",
            tambon: reportData.tambon || "",
          });
          setFormValue({
            ...formValue,
            confirm: reportData.verified_by_staff,
            level: reportData.damage_level || 1,
            distant: reportData.alert_distance || null,
            notification: reportData.send_notification || false,
          });
        }
      } catch (error) {
        console.log("Error fetching report detail", error);
      } finally {
        setLoaing(false);
      }
    };
    fetchReport();
  }, []);

  useEffect(() => {
    let updatedValues = { ...formValue };

    if (!formValue.confirm) {
      updatedValues = {
        ...updatedValues,
        level: null,
        distant: null,
        notification: false,
      };
    }
    if (!formValue.notification) {
      updatedValues = { ...updatedValues, distant: null };
    }

    setFormValue(updatedValues);
  }, [formValue.confirm, formValue.notification]);

  return (
    <Layout admin={true}>
      {loading ? (
        <Loading />
      ) : (
        <MDBContainer
          fluid
          className="d-flex align-items-center justify-content-center min-vh-100"
          style={{ background: "linear-gradient(white, #84BBFF)" }}
        >
          <MDBCard
            className="m-5"
            style={{
              width: "1200px",
              minWidth: "350px",
              borderRadius: "20px",
            }}
          >
            <MDBCardBody className="px-md-5 px-3">
              <h2
                className="text-uppercase text-center mb-5"
                style={{ color: "black", fontFamily: "Kanit-SemiBold" }}
              >
                รายละเอียดการแจ้งข้อมูล
              </h2>
              <div className="responsive-container gap-3">
                <div className="left-column d-flex flex-column">
                  <ShowData
                    title="เรื่อง: "
                    content={reportValue.title || "-"}
                  />
                  {reportValue.audio_title && (
                    <audio
                      className="w-100 mb-3"
                      controls
                      preload={true}
                      src={`${API_URL}/assets/${reportValue.audio_title}`}
                    />
                  )}
                  <ShowData
                    title="ผู้แจ้ง: "
                    content={reportValue.reporter || "-"}
                  />
                  <ShowData
                    title="แจ้งเมื่อ: "
                    content={convertToThaiDate(reportValue.reportTime) || "-"}
                  />
                  <div className="d-flex flex-row align-items-center gap-2">
                    <h5>ประเภท : </h5>
                    <div className="d-flex flex-row gap-2">
                      {reportValue.type?.map((accident, index) => {
                        const mapValue = mappingTypeValue(accident);
                        return (
                          <div
                            key={index}
                            className="bg-black text-white p-1 p-sm-2 rounded-5 mb-2 text-truncate"
                          >
                            {mapValue !== "อื่นๆ"
                              ? mapValue
                              : reportValue.custom_type}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <h5>คำอธิบาย :</h5>
                  <div
                    className="border rounded-5 p-2"
                    style={{ backgroundColor: "#e8e8e8" }}
                  >
                    <p className="my-0">
                      {reportValue.description || "ไม่มีคำอธิบาย"}
                    </p>
                    {reportValue.audio_description && (
                      <audio
                        className="w-100 m-2"
                        controls
                        preload={true}
                        src={`${API_URL}/assets/${reportValue.audio_description}`}
                      />
                    )}
                  </div>
                </div>
                <div className="right-column d-flex flex-column">
                  <ShowData
                    title="ชื่อสถานที่ : "
                    content={reportValue.location_title || "-"}
                  />
                  <ShowData
                    title="ที่อยู่: "
                    content={
                      `${reportValue.region} ${reportValue.province}${" "}
                  ${reportValue.district} ${reportValue.tambon}` || "-"
                    }
                  />
                  <div className="my-3" style={{ position: "relative" }}>
                    <Map position={selectedPosition} fixPosition={true} />
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column align-items-center w-100 mb-3">
                <div className="d-flex justify-content-center w-100">
                  <button
                    onClick={() => setShowInputs(!showInputs)}
                    className="btn my-3"
                    style={{
                      backgroundColor: "#5E8FCB",
                      color: "white",
                      fontFamily: "Kanit-Regular",
                      fontSize: "1rem",
                      width: "100%",
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "8px 16px",
                    }}
                  >
                    <span style={{ flex: 1, textAlign: "center" }}>
                      หลักฐานประกอบ
                    </span>
                    <img
                      src="arrow.png"
                      alt={showInputs ? "Hide" : "Show"}
                      style={{ height: "16px", marginLeft: "8px" }}
                    />
                  </button>
                </div>
                {showInputs && (
                  <div className="d-flex flex-column w-100">
                    <div className="d-flex flex-column flex-md-row w-100 gap-2">
                      <div className="w-100 w-md-50">
                        <h5>รูปภาพ:</h5>
                        <div
                          className="d-flex justify-content-center align-items-center border border-gray-300 rounded"
                          style={{
                            height: "40vh",
                            maxHeight: "300px",
                          }}
                        >
                          {reportValue.image ? (
                            <img
                              id="reportImage"
                              style={{
                                objectFit: "contain",
                                height: "40vh",
                                maxHeight: "300px",
                                width: "100%",
                              }}
                              src={`${API_URL}/assets/${reportValue.image}`}
                              alt="reportImage"
                            />
                          ) : (
                            <p className="p-2 m-0">ไม่มีไฟล์ภาพ</p>
                          )}
                        </div>
                      </div>
                      <div className="w-100 w-md-50 justify-content-between align-items-center">
                        <h5>วิดิโอ:</h5>
                        <div
                          className="d-flex justify-content-center align-items-center border border-gray-300 rounded h-100"
                          style={{
                            height: "40vh",
                            maxHeight: "300px",
                          }}
                        >
                          {reportValue.video ? (
                            <video
                              controls
                              className="border border-gray-300 rounded"
                              style={{
                                objectFit: "contain",
                                height: "40vh",
                                maxHeight: "300px",
                                width: "100%",
                              }}
                            >
                              <source
                                src={`${API_URL}/assets/${reportValue.video}`}
                                type="video/mp4"
                              />
                              Your browser does not support the video tag.
                            </video>
                          ) : (
                            <p className="p-2 m-0">ไม่มีไฟล์วิดิโอ</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 d-flex flex-row align-items-end gap-2">
                      <h5 className="m-0">ไฟล์เสียง:</h5>
                      {reportValue.audio_other ? (
                        <audio
                          controls
                          className="w-100"
                          src={`${API_URL}/assets/${reportValue.audio_other}`}
                        />
                      ) : (
                        <p className="m-0">ไม่มีไฟล์เสียง</p>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <h5>การประเมินของผู้ดูแล</h5>
              <h6>การอนุมัติ</h6>
              <div className="mb-3 d-flex align-items-center gap-3">
                <label
                  className="form-check-label d-flex align-items-center"
                  style={{ fontFamily: "Kanit-Regular" }}
                >
                  <input
                    type="radio"
                    name="inlineRadioOptions"
                    checked={formValue.confirm === "true"}
                    onClick={() =>
                      setFormValue({
                        ...formValue,
                        confirm: "true",
                      })
                    }
                    className="form-check-input me-2"
                  />
                  อนุมัติ
                </label>
                <label
                  className="form-check-label d-flex align-items-center"
                  style={{ fontFamily: "Kanit-Regular" }}
                >
                  <input
                    type="radio"
                    name="inlineRadioOptions"
                    checked={formValue.confirm === "false"}
                    onClick={() =>
                      setFormValue({
                        ...formValue,
                        confirm: "false",
                      })
                    }
                    className="form-check-input me-2"
                  />
                  ไม่อนุมัติ
                </label>
              </div>
              {formValue.confirm === "true" && (
                <>
                  <form
                    style={{
                      marginBottom: "12px",
                      fontFamily: "Kanit-Regular",
                    }}
                  >
                    <label style={{ fontFamily: "Kanit-Semibold" }}>
                      ระดับความรุนแรง :
                    </label>
                    <select
                      className="py-1 px-2 text-gray-900 border border-#bdbdbd rounded transition-all duration-100 responsive-input"
                      onChange={(e) =>
                        setFormValue({
                          ...formValue,
                          level: e.target.value,
                        })
                      }
                      value={formValue.level || ""}
                    >
                      <option value="" disabled selected hidden>
                        กรุณาเลือกระดับ
                      </option>
                      <option value={1}>ต่ำมาก</option>
                      <option value={2}>ต่ำ</option>
                      <option value={3}>ปานกลาง</option>
                      <option value={4}>สูง</option>
                      <option value={5}>สูงมาก</option>
                    </select>
                  </form>
                  <label
                    className="form-check-label d-flex align-items-center mb-2"
                    style={{ fontFamily: "Kanit-Semibold" }}
                  >
                    <input
                      type="checkbox"
                      onChange={() =>
                        setFormValue({
                          ...formValue,
                          notification: !formValue.notification,
                        })
                      }
                      checked={formValue.notification}
                      className="form-check-input me-2"
                    />
                    ส่งแจ้งเตือนประชาชนใกล้เคียง
                  </label>

                  {formValue.notification && (
                    <div style={{ fontFamily: "Kanit-Regular" }}>
                      <label style={{ fontFamily: "Kanit-Semibold" }}>
                        รัศมีการส่งแจ้งเตือนจากจุดเกิดเหตุ (กม.)
                      </label>
                      <input
                        type="number"
                        placeholder="กรุณาระบุขนาดรัศมีการแจ้งเตือน"
                        className="form-control responsive-input"
                        value={formValue.distant || ""}
                        onChange={(e) =>
                          setFormValue({
                            ...formValue,
                            distant: e.target.value,
                          })
                        }
                      />
                    </div>
                  )}
                </>
              )}

              <MDBBtn
                type="submit"
                className="btn btn-lg w-100 rounded-6 mt-4"
                style={{
                  backgroundColor: "#B62319",
                  borderColor: "#B62319",
                  boxShadow: "none",
                  fontFamily: "Kanit-Regular",
                  height: "40px",
                }}
                onClick={handleSubmit}
              >
                บันทึก
              </MDBBtn>
            </MDBCardBody>
          </MDBCard>
          <ModalAlert
            show={modalValue.showModal}
            setShow={(show) => (show ? null : modalValue.onModalClose())}
            title={modalValue.modalTitle}
            onClose={modalValue.onModalClose}
          >
            {modalValue.modalMessage}
          </ModalAlert>
        </MDBContainer>
      )}
      <style jsx>{`
        .responsive-container {
          display: flex;
          flex-wrap: wrap;
        }

        .left-column,
        .right-column {
          flex: 1;
          min-width: 300px;
        }

        .responsive-input {
          width: 100%;
        }

        @media (max-width: 1200px) {
          .responsive-container {
            flex-direction: column;
          }
        }
      `}</style>
    </Layout>
  );
};

export default ReportDetail;
