export const mappingTypeValue = (input) => {
  const translations = {
    life: "ชีวิตและร่างกาย",
    sex: "เพศ",
    property: "ทรัพย์",
    drug: "ยาเสพติด",
  };
  return translations[input] || "อื่นๆ";
};

export const mappingSex = (input) => {
  const sexual = {
    male: "ชาย",
    female: "หญิง",
    none: "ไม่ระบุ",
  }
  return sexual[input] || "ไม่มีข้อมูล"
}

export const mappingLevel = (level) => {
  switch (level) {
      case 1:
        return "ต่ำมาก";
      case 2:
        return "ต่ำ";
      case 3:
        return "ปานกลาง";
      case 4:
        return "สูง";
      case 5:
        return "สูงมาก";
      default:
        return "ยังไม่กำหนด";
    }
}