import { useEffect, useState } from "react";
import { useNavigate, Link, NavLink, useLocation } from "react-router-dom";

import {
  MDBNavbar,
  MDBContainer,
  MDBNavbarToggler,
  MDBNavbarItem,
  MDBCollapse,
  MDBIcon,
  MDBNavbarNav,
} from "mdb-react-ui-kit";
import { useAuth } from "../auth/AuthContext";

const Navbar = ({ admin = false }) => {
  const { isAuthenticated, adminMode, logout, changeAdminMode} = useAuth();
  const [showNav, setShowNav] = useState(false);
  const location = useLocation()
  const navigate = useNavigate();

  const handleLogout = async (e) => {
    e.preventDefault();
    await logout();
    setShowNav(false)
    navigate("/");
  };

  useEffect(()=> {
    const allowedAdminPaths = ['/admin-report', '/admin-alert', '/profile', '/alert-add'];
    const isReportPath = location.pathname.startsWith('/report-detail');
    if (!allowedAdminPaths.includes(location.pathname) && !isReportPath) {
      changeAdminMode(false)
    }
  }, [location])

  return (
    <>
      <MDBNavbar
        expand="xl"
        light
        bgColor="light"
        style={{ fontFamily: "Kanit-Regular" }}
      >
        <MDBContainer fluid>
          <Link className="navbar-brand" to='/'>
            <img
              src="logo192.png"
              height="30"
              alt=""
              loading="lazy"
              style={{ fontFamily: "Kanit-Regular" }}
            />
            Safe Points
          </Link>
          <MDBNavbarToggler
            type="button"
            data-target="#navbarToggler"
            aria-controls="navbarToggler"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setShowNav(!showNav)}
          >
            <MDBIcon icon="bars" fas />
          </MDBNavbarToggler>
          <MDBCollapse navbar show={showNav} className="px-1">
            {!adminMode ? (
              <MDBNavbarNav left fullWidth={false} className="mb-2 mb-lg-0">
                <MDBNavbarItem>
                  <NavLink className="nav-link" to="/">
                    หน้าหลัก
                  </NavLink>
                </MDBNavbarItem>
                <MDBNavbarItem>
                  <NavLink className="nav-link" to="/planning">
                    วางแผนการเดินทาง
                  </NavLink>
                </MDBNavbarItem>
                <MDBNavbarItem>
                  <NavLink className="nav-link" to="/add-event">
                    แจ้งข้อมูลข่าวสาร
                  </NavLink>
                </MDBNavbarItem>
                <MDBNavbarItem>
                  <NavLink className="nav-link" to="/faq">
                    ศูนย์ช่วยเหลือ
                  </NavLink>
                </MDBNavbarItem>
              </MDBNavbarNav>
            ) : (
              <MDBNavbarNav left fullWidth={false} className="mb-2 mb-lg-0">
                <MDBNavbarItem>
                  <NavLink className="nav-link" to="/admin-report">
                    รายการแจ้งข้อมูลข่าวสาร
                  </NavLink>
                </MDBNavbarItem>
                <MDBNavbarItem>
                  <NavLink className="nav-link" to="/admin-alert">
                    รายการแจ้งเตือนเหตุ
                  </NavLink>
                </MDBNavbarItem>
              </MDBNavbarNav>
            )}
            {isAuthenticated ? (
              <MDBNavbarNav right fullWidth={false} className="mb-2 mb-lg-0">
                {!admin && (
                  <MDBNavbarItem>
                    <NavLink className="nav-link" to="/notification">
                      การแจ้งเตือน
                    </NavLink>
                  </MDBNavbarItem>
                )}
                <MDBNavbarItem>
                  <NavLink className="nav-link" to="/profile">
                    ข้อมูลส่วนตัว
                  </NavLink>
                </MDBNavbarItem>
                <MDBNavbarItem>
                  <NavLink
                    className="nav-link"
                    onClick={handleLogout}
                    style={{ fontFamily: "Kanit-Regular" }}
                  >
                    ออกจากระบบ
                  </NavLink>
                </MDBNavbarItem>
              </MDBNavbarNav>
            ) : (
              <MDBNavbarNav right fullWidth={false} className="mb-2 mb-lg-0">
                <MDBNavbarItem>
                  <NavLink className="nav-link" to="/login">
                    เข้าสู่ระบบ
                  </NavLink>
                </MDBNavbarItem>
                <MDBNavbarItem>
                  <NavLink className="nav-link" to="/register">
                    สมัครเข้าใช้งาน
                  </NavLink>
                </MDBNavbarItem>
              </MDBNavbarNav>
            )}
          </MDBCollapse>
        </MDBContainer>
      </MDBNavbar>
    </>
  );
};

export default Navbar;
