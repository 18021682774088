import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { PRIVACY_TEXT, ACCEPT_TEXT } from "../../libs/constants";
import { Layout } from "../../components";
import parse from "html-react-parser";

import {
  MDBBtn,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBValidation,
  MDBCheckbox,
} from "mdb-react-ui-kit";
import { useAuth } from "../../auth/AuthContext";

const AcceptTerm = () => {
  const { acceptTerms } = useAuth()
  const [formValue, setFormValue] = useState({
    confirm: false,
  });
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(formValue?.confirm){
      const {success} = await acceptTerms();
      if (success) {
        navigate("/");
      }
    }
  };

  return (
    <Layout>
      <MDBContainer className="d-flex align-items-center justify-content-center">
        <MDBCard
          className="m-5"
          style={{ maxWidth: "600px", minWidth: "400px" }}
        >
          <MDBCardBody className="px-5">
            <h2 className="text-uppercase text-center">
              นโยบายความเป็นส่วนตัว
            </h2>
            <h2 className="text-uppercase text-center mb-5">
              (Privacy Policy Notice)
            </h2>
            <div
              className="overflow-auto mb-5 bg-secondary bg-opacity-25"
              style={{ maxHeight: "200px" }}
            >
              {parse(PRIVACY_TEXT)}
            </div>
            <MDBValidation onSubmit={handleSubmit}>
              <MDBCheckbox
                label={ACCEPT_TEXT}
                id="invalidCheck"
                required
                name="confirm"
                onChange={(e) => {
                  setFormValue({ confirm: e.target.checked });
                }}
              />
              <MDBBtn type="submit" className="my-4 w-100" size="lg">
                ยืนยัน
              </MDBBtn>
            </MDBValidation>
            <p className="text-center">
              <Link className="text-secondary" to="/delete-account">
                ลบบัญชีผู้ใช้งาน
              </Link>
            </p>
          </MDBCardBody>
        </MDBCard>
      </MDBContainer>
    </Layout>
  );
};

export default AcceptTerm;
