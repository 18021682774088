import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { RiskReport } from "../../components";
import { ApiHelper } from "../../libs/api";

const Report = () => {
  const [searchParams] = useSearchParams();
  const [params, setParams] = useState({});
  const [riskReport, setRiskReport] = useState({});

  const handleParams = async () => {
    const { lat, long, province, tambon, date, time } = params;
    if (province && tambon && date && time) {
      // const { success, data } = await ApiHelper.getCrimeRiskReport({
      //   lat,
      //   long,
      //   province,
      //   tambon,
      //   date,
      //   time,
      // });
      // if (success) {
      //   setRiskReport(data);
      // }
    }
  };

  useEffect(() => {
    handleParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    setParams(Object.fromEntries([...searchParams]));
  }, [searchParams]);

  return (
    <div className="vh-100 p-5">
      <h4>รายงานข้อมูลความเสี่ยง</h4>
      <h5>{`${params?.date} | ${params?.time}`}</h5>
      <h6>{`${params?.tambon}, ${params?.province}`}</h6>
      <RiskReport prob={riskReport?.prob} />
    </div>
  );
};
export default Report;
