import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Layout, RiskInfo, RiskAds } from "../../components";

const Risk = () => {
  const [searchParams] = useSearchParams();
  const [params, setParams] = useState({});

  useEffect(() => {
    setParams(Object.fromEntries([...searchParams]));
  }, [searchParams]);

  return (
    <Layout>
      <RiskInfo
        province={params?.province}
        district={params?.district}
        tambon={params?.tambon}
        date={params?.date}
        time={params?.time}
        isPlanning={true}
      />
      <RiskAds />
    </Layout>
  );
};
export default Risk;
