import { useEffect, useState } from "react";
import { ApiHelper } from "../../libs/api";
import { NotificationItem, Layout, Loading } from "../../components";

import { MDBContainer } from "mdb-react-ui-kit";

const Notification = () => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoaing] = useState(true);

  const fetchNotifications = async (lat, long) => {
    try {
      const { success, data, error } = await ApiHelper.getNotifications(lat, long);
      if (success) {
        if (data) {
          const transformedData = data
            .filter((item) => item.notification_update == 0)
            .map((item) => {
              return {
                ...item,
                location:
                  typeof item.location === "string"
                    ? JSON.parse(item.location)
                    : item.location,
                type:
                  typeof item.type === "string"
                    ? JSON.parse(item.type)
                    : item.type,
              };
            });
          setNotifications(transformedData);
        }
      }
    } catch (error) {
      console.log("Error fetching notification", error);
    } finally {
      setLoaing(false);
    }
  };

  useEffect(() => {
    let pos = null;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        pos = [position?.coords?.longitude, position?.coords?.latitude];
      });
      if (pos) fetchNotifications(pos[1], pos[0]);
      else fetchNotifications(13.736717, 100.523186);
    }
  }, []);

  return (
    <Layout>
      {loading ? (
        <Loading />
      ) : (
        <MDBContainer fluid>
          <div className="p-3 p-sm-4 d-flex w-100 align-items-center">
            <h2 className="text-uppercase text-left">การแจ้งเตือนเหตุ</h2>
          </div>
          <div className="d-flex flex-column gap-3 p-5 pt-2">
            {notifications.length !== 0 ? (
              notifications.map((item, idx) => {
                return (
                  <NotificationItem
                    key={`notification-${idx}`}
                    title={item.title}
                    type={item.type}
                    description={item.description}
                    image={item.image}
                    location_title={item.location_title}
                    date_created={item.date_created}
                    damage_level={item.damage_level}
                  />
                );
              })
            ) : (
              <div className="d-flex flew-column justify-content-center">
                <p>ไม่มีข้อมูล</p>
              </div>
            )}
          </div>
        </MDBContainer>
      )}
    </Layout>
  );
};

export default Notification;
