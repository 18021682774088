import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";

import { AuthHelper } from "../../libs/auth";
import { DEFAULT_ERROR_MESSAGE } from "../../libs/constants";
import { ApiHelper } from "../../libs/api";
import { validateEmail } from "../../libs/function";

import { ModalAlert, Layout } from "../../components";
import {
  MDBBtn,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBValidation,
  MDBInput,
} from "mdb-react-ui-kit";
import { useAuth } from "../../auth/AuthContext";


const Register = () => {
  const {checkAuth, login} = useAuth()
  const navigate = useNavigate();
  const [formValue, setFormValue] = useState({
    email: "",
    password: "",
    repeatPassword: "",
  });
  const [modalValue, setModalValue] = useState({
    modalTitle: "",
    modalMessage: "",
    showModal: false,
    onModalClose: () => {},
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const onChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };
  const handleLineLogin = () => {navigate("/login-line?platform=web");};

  const handleThaiIdLogin = () => {navigate("/login-thaid?platform=web");};
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email, password, repeatPassword } = formValue;
    if (email && password) {
      if(!validateEmail(email)){
        setModalValue({
          modalTitle: "เกิดข้อผิดพลาด",
          modalMessage: "กรุณาตรวจสอบอีเมลให้ถูกต้อง",
          showModal: true,
          onModalClose: () =>
            setModalValue({ ...modalValue, showModal: false }),
        });
      }
      else if (password !== repeatPassword) {
        setModalValue({
          modalTitle: "เกิดข้อผิดพลาด",
          modalMessage: "รหัสผ่านไม่ตรงกัน กรุณากรอกรหัสผ่านใหม่อีกครั้ง",
          showModal: true,
          onModalClose: () =>
            setModalValue({ ...modalValue, showModal: false }),
        });
      } else if (password.length < 6) {
        setModalValue({
          modalTitle: "เกิดข้อผิดพลาด",
          modalMessage: "รหัสผ่านต้องมีความยาวตั้งแต่ 6 ตัวอักษรขึ้นไป",
          showModal: true,
          onModalClose: () =>
            setModalValue({ ...modalValue, showModal: false }),
        });
      } else {
        const { success, error } = await AuthHelper.register(email, password);
        if (success) {
          setModalValue({
            modalTitle: "ลงทะเบียนสำเร็จ",
            modalMessage: "กำลังเข้าสู่ระบบ...",
            showModal: true,
            onModalClose: () =>
              setModalValue({ ...modalValue, showModal: false }),
          });
          const { success: loginSuccess } = await login(email, password);
          await ApiHelper.sendVerificationEmail()
          if (loginSuccess) {
            await checkAuth();
            navigate("/verify-email");
          } else {
            navigate("/");
          }
        } else {
          const message = error?.message?.toLowerCase();
          let errorMsg = DEFAULT_ERROR_MESSAGE;
          if (message.includes("must be a valid email")) {
            errorMsg = "คุณกรอกอีเมลไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง";
          } else if (message.includes("has to be unique")) {
            errorMsg = "อีเมลนี้ถูกใช้งานไปแล้ว กรุณาลองใหม่อีกครั้ง";
          }
          setModalValue({
            modalTitle: "เกิดข้อผิดพลาด",
            modalMessage: errorMsg,
            showModal: true,
            onModalClose: () =>
              setModalValue({ ...modalValue, showModal: false }),
          });
        }
      }
    }
  };

  return (
    <Layout>
      <MDBContainer
        fluid
        className="d-flex align-items-center justify-content-center min-vh-100"
        style={{ background: "linear-gradient(white, #84BBFF)" }}
      >
        <MDBCard
          className="m-md-5 m-3"
          style={{
            flex: 1,
            maxWidth: "500px",
            borderRadius: "20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              background:
                "linear-gradient(to right, rgba(132, 187, 255, 0.39) 10%, rgba(174, 209, 251, 0.39) 37%, rgba(195, 219, 249, 0.39) 50%, rgba(132, 187, 255, 0.39) 90%)",
              width: "100%",
              padding: "20px",
              borderTopLeftRadius: "20px",
              borderTopRightRadius: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              paddingLeft: "40px",
            }}
          >
            <img
              src="logo512.png"
              alt="logo"
              style={{
                marginRight: "20px",
                height: "75px",
                width: "75px",
              }}
            />
            <div style={{ textAlign: "left", paddingTop: "10px" }}>
              {" "}
              <h2
                className="text-uppercase mb-2"
                style={{
                  fontFamily: "Kanit-SemiBold",
                  fontSize: "2rem",
                }}
              >
                สมัครเข้าใช้งาน
              </h2>
            </div>
          </div>
          <MDBCardBody className="px-4 px-md-5" style={{ flex: 1 }}>
            <MDBValidation onSubmit={handleSubmit}>
              <p style={{ color: "black", fontFamily: "Kanit-Regular" }}>
                อีเมล <span style={{ color: "red" }}>*</span>
              </p>
              <MDBInput
                wrapperClass="mb-4"
                size="lg"
                id="กรุณากรอกอีเมลของผู้ใช้งาน"
                type="email"
                name="email"
                value={formValue.email}
                onChange={onChange}
                required
                placeholder="กรุณากรอกอีเมลของผู้ใช้งาน"
              />

              <p style={{ color: "black", fontFamily: "Kanit-Regular" }}>
                รหัสผ่าน <span style={{ color: "red" }}>*</span>
              </p>
              <div style={{ position: "relative" }}>
                <MDBInput
                  wrapperClass="mb-4"
                  size="lg"
                  id="กรุณากรอกรหัสผ่าน"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={formValue.password}
                  onChange={onChange}
                  required
                  placeholder="กรุณากรอกรหัสผ่าน"
                />
                <img
                  src={showPassword ? "eye-hide.png" : "eye.png"}
                  alt="toggle password visibility"
                  onClick={togglePasswordVisibility}
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                    width: "20px",
                    height: "20px",
                  }}
                />
              </div>
              <p style={{ color: "black", fontFamily: "Kanit-Regular" }}>
                ยืนยันรหัสผ่าน <span style={{ color: "red" }}>*</span>
              </p>
              <div style={{ position: "relative" }}>
                <MDBInput
                  wrapperClass="mb-4"
                  size="lg"
                  id="input-repeat-password"
                  type={showPassword2 ? "text" : "password"}
                  name="repeatPassword"
                  value={formValue.repeatPassword}
                  onChange={onChange}
                  required
                  placeholder="กรุณากรอกยืนยันรหัสผ่านอีกครั้ง"
                />
                <img
                  src={showPassword2 ? "eye-hide.png" : "eye.png"}
                  alt="toggle password visibility"
                  onClick={togglePasswordVisibility2}
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                    width: "20px",
                    height: "20px",
                  }}
                />
              </div>
              <MDBBtn
                type="submit"
                className="mb-4 w-100 rounded-6"
                size="lg"
                style={{
                  backgroundColor: "#B62319",
                  borderColor: "#B62319",
                  boxShadow: "none",
                  fontFamily: "Kanit-Regular",
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                สมัครเข้าใช้งาน
              </MDBBtn>
            </MDBValidation>
            <div
              className="text-center mb-4"
              style={{ fontFamily: "Kanit-Regular" }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <hr
                  style={{
                    borderTop: "1px solid #000",
                    width: "45%",
                    margin: "0 10px",
                  }}
                />
                <span>หรือ</span>
                <hr
                  style={{
                    borderTop: "1px solid #000",
                    width: "45%",
                    margin: "0 10px",
                  }}
                />
              </div>
            </div>
            <div className="text-center">
              <MDBBtn
                type="button"
                className="mb-4 w-100 rounded-4"
                size="lg"
                style={{
                  backgroundColor: "#06C755",
                  borderColor: "#06C755",
                  boxShadow: "none",
                  fontFamily: "Kanit-Regular",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={handleLineLogin}
              >
                <img
                  src="LineLogo.png"
                  alt="Line"
                  style={{
                    width: "24px",
                    height: "24px",
                    marginRight: "10px",
                  }}
                />
                เข้าสู่ระบบผ่านไลน์
              </MDBBtn>
              <MDBBtn
                type="button"
                className="mb-4 w-100 rounded-4"
                size="lg"
                style={{
                  backgroundColor: "#030650",
                  borderColor: "#030650",
                  boxShadow: "none",
                  fontFamily: "Kanit-Regular",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={handleThaiIdLogin}
              >
                <img
                  src="ThaiDLogo.png"
                  alt="ThaiD"
                  style={{
                    width: "24px",
                    height: "24px",
                    marginRight: "10px",
                  }}
                />
                เข้าสู่ระบบ ผ่าน ThaiD
              </MDBBtn>
            </div>
            <p
              className="mb-5 pb-lg-2 text-center"
              style={{ color: "#393f81", fontFamily: "Kanit-Regular" }}
            >
              เคยสมัครเข้าใช้งานแล้ว?{" "}
              <Link
                to="/login"
                style={{
                  textDecoration: "underline",
                  fontFamily: "Kanit-Regular",
                }}
              >
                เข้าสู่ระบบ
              </Link>
            </p>
          </MDBCardBody>
        </MDBCard>
        <ModalAlert
          show={modalValue.showModal}
          setShow={(show) => (show ? null : modalValue.onModalClose())}
          title={modalValue.modalTitle}
          onClose={modalValue.onModalClose}
        >
          {modalValue.modalMessage}
        </ModalAlert>
      </MDBContainer>
    </Layout>
  );
};

export default Register;
