import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { nanoid } from "nanoid";
import { API_URL, REDIRECT_URI_APPLICATION_APK ,GOOGLE_PLAY_URL} from "../../libs/constants";
import { useAuth } from "../../auth/AuthContext";

const LineThaiD = () => {
  const { setToken, refreshToken } = useAuth();
  const [deepLinkUrl, setDeepLinkUrl] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get("code");
        if (code === null && code !== "No_trigger") {
          const currentUrl = decodeURIComponent(window.location.href);
          const platform = new URL(currentUrl).searchParams.get("platform");
          const thaid_userId = new URL(currentUrl).searchParams.get(
            "thaid_userId"
          );
          if (platform) {
            sessionStorage.setItem("platform", platform);
          }
          if (thaid_userId) {
            sessionStorage.setItem("thaid_userId", thaid_userId);
          }

          const state = nanoid();
          const respond_web = await axios.post(`${API_URL}/thaid/login`, {
            state: state,
            userID: thaid_userId,
          });

          const redirectUrl = respond_web.data.url;
          if (redirectUrl) {
            window.location.href = redirectUrl;
          } else {
            /*  setErrorMsg("Failed to get the redirect URL."); */
          }
        } else if (code && code !== "No_trigger") {
          const thaid_userId = sessionStorage.getItem("thaid_userId");
          const requestBody = {
            code: code,
          };
          if (thaid_userId) {
            requestBody.thaid_userId = thaid_userId;
          }
          const respond_web_verify = await axios.post(
            `${API_URL}/thaid/auth`,
            requestBody
          );
          const platform = sessionStorage.getItem("platform");

          if (platform === "web") {
            const accessToken = respond_web_verify?.data.data.accessToken;
            const refresh_token = respond_web_verify?.data.data.refreshToken;
            if (accessToken && refresh_token) {
              try {
                const data = await refreshToken(refresh_token);
                await setToken(data.access_token, data.refresh_token);
                navigate("/");
              } catch (err) {
                console.log("Error setting Thaid login");
                navigate("/");
              }
            } else {
              /* setErrorMsg("Failed to get tokens from backend."); */
            }
          } else {
            const accessToken = respond_web_verify?.data.data.accessToken;
            const refreshToken = respond_web_verify?.data.data.refreshToken;
            if (accessToken && refreshToken) {
              const deepLink = `${REDIRECT_URI_APPLICATION_APK}?access_token=${accessToken}&refresh_token=${refreshToken}&form_login_method=thaid-login`;
              setDeepLinkUrl(deepLink);
              window.history.replaceState(
                null,
                null,
                "/login-line?code=No_trigger"
              );
            } else {
              /* setErrorMsg("Failed to get tokens from backend."); */
            }
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    if (deepLinkUrl) {
      window.location.replace(deepLinkUrl);
    }
  }, [deepLinkUrl]);
  return <div>{deepLinkUrl && <a href={deepLinkUrl}>deeplink</a>}</div>;
};

export default LineThaiD;
