
export const saveAgeAndGender = (birthdate, gender) => {
  let age = null;
  let formattedGender = "";
  
  if (birthdate) {
    const birthDateObj = new Date(birthdate);
    if (!isNaN(birthDateObj.getTime())) {
      const currentYear = new Date().getFullYear();
      const birthYear = birthDateObj.getFullYear();
      age = currentYear - birthYear;
    }
  }

  if (gender) {
    formattedGender =
      gender.charAt(0).toUpperCase() + gender.slice(1).toLowerCase();
  }

  return { age, formattedGender };
};
