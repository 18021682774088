import { useState, useEffect } from "react";
import dayjs from "dayjs";

import { RiskCard } from ".";

import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
} from "mdb-react-ui-kit";

const RiskTab = ({ data, date = null, advices }) => {
  const [fillActive, setFillActive] = useState("tab0");
  const [crimeRisks, setCrimeRisks] = useState({});
  const MAX_DAYS = 7;

  const handleFillClick = (value) => {
    if (value === fillActive) {
      return;
    }
    setFillActive(value);
  };

  useEffect(() => {
    if (data) {
      setCrimeRisks(data);
    } else {
      // init crime risk
      const risks = {};
      for (let i = 0; i < MAX_DAYS; i++) {
        const d = date ? dayjs(date).add(i, "d") : dayjs().add(i, "d");
        const df = d.format("YYYY-MM-DD");
        risks[df] = {};
        for (let j = 0; j < 24; j++) {
          const t = d.hour(j).minute(0);
          const tf = t.format("HH:mm");
          risks[df][tf] = {
            level: null,
            description: null,
            prob: null,
          };
        }
      }
      setCrimeRisks(risks);
    }
  }, [data, date]);

  return (
    <div style={{ maxWidth: "600px" }}>
      <MDBTabs fill className="mb-3">
        <div className="d-flex flex-row w-100" style={{ overflow: "auto" }}>
          {Object.keys(crimeRisks).map((df, idx) => {
            const d = dayjs(df).toDate();
            const result = d.toLocaleDateString("th-TH", {
              year: "numeric",
              month: "short",
              day: "numeric",
              weekday: "short",
            });
            if (idx < MAX_DAYS) {
              return (
                <MDBTabsItem key={`tab${idx}`}>
                  <MDBTabsLink
                    onClick={() => handleFillClick(`tab${idx}`)}
                    active={fillActive === `tab${idx}`}
                    style={{ minWidth: "150px" }}
                  >
                    {result}
                  </MDBTabsLink>
                </MDBTabsItem>
              );
            }
            return null;
          })}
        </div>
      </MDBTabs>
      <MDBTabsContent>
        {Object.keys(crimeRisks).map((df, tabIdx) => {
          if (tabIdx < MAX_DAYS) {
            return (
              <MDBTabsPane
                key={`content${tabIdx}`}
                show={fillActive === `tab${tabIdx}`}
              >
                <div
                  className="d-flex flex-row w-100"
                  style={{ overflow: "auto" }}
                >
                  {Object.keys(crimeRisks[df]).map((tf, cardIdx) => {
                    return (
                      <div className="me-2" key={`tab${tabIdx}-card${cardIdx}`}>
                        <RiskCard
                          width="9rem"
                          date={df}
                          time={tf}
                          level={crimeRisks[df][tf]?.crime_level}
                          description={crimeRisks[df][tf]?.description}
                          prob={crimeRisks[df][tf]?.prob}
                          showDescription={false}
                          advices={advices}
                        />
                      </div>
                    );
                  })}
                </div>
              </MDBTabsPane>
            );
          }
          return null;
        })}
      </MDBTabsContent>
    </div>
  );
};
export default RiskTab;
