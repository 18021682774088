import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { MDBContainer } from "mdb-react-ui-kit";
import { Layout, AdminReportCard, Loading } from "../../components";

import { ApiHelper } from "../../libs/api";

const AdminAlert = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoaing] = useState(true);

  const fetchReport = async () => {
    try {
      const { success, data, error } = await ApiHelper.getEventNotification();
      if (success) {
        const rawData = data;
        const mappingData = rawData.map((item) => {
          const daysAgo = Math.floor(
            (new Date() - new Date(item.date_created)) / (1000 * 3600 * 24)
          );
          const type = item.type.map((word) =>
            word === "custom" ? item.custom_type : word
          );
          return {
            id: item.id,
            title: item.title,
            description: item.description,
            damage_level: item.damage_level,
            image: item.image,
            location: item.location_title,
            typeOfAccident: type,
            date_created: item.date_created,
            daysAgo: daysAgo,
            level: item.damage_level,
            send_notification: item.send_notification,
            notification_update: item.notification_update,
          };
        });
        setData(mappingData);
      } else {
        if (error.extensions.code === "FORBIDDEN")
          setError("คุณไม่มีสิทธิ์เข้าถึงหน้านี้หรือดำเนินการนี้");
        else setError("เกิดข้อผิดพลาด");
      }
    } catch (error) {
      console.log("Error fetching admin alert:", error);
    } finally {
      setLoaing(false);
    }
  };

  const cancelNotification = async (eventId) => {
    try {
      await ApiHelper.cancelNotification(eventId);
      fetchReport();
    } catch (err) {
      console.log("Error cancel notification");
    }
  };

  useEffect(() => {
    fetchReport();
  }, []);

  return (
    <Layout admin={true}>
      {loading ? (
        <Loading />
      ) : (
        <MDBContainer fluid className="bg-white">
          <div className="p-4 w-100" style={{ minWidth: "350px" }}>
            <div className="d-flex align-items-center justify-content-between">
              <h2
                className="text-uppercase text-left"
                style={{ fontFamily: "Kanit-Regular" }}
              >
                รายการแจ้งเตือนเหตุ
              </h2>
              {error !== "คุณไม่มีสิทธิ์เข้าถึงหน้านี้หรือดำเนินการนี้" && (
                <button
                  className="btn d-flex align-items-center"
                  style={{
                    backgroundColor: "#B62319",
                    color: "white",
                    borderRadius: "2rem",
                    padding: "0.5rem 1rem",
                    fontFamily: "Kanit-Regular",
                    fontSize: "1rem",
                  }}
                  onClick={() => navigate("/alert-add")}
                >
                  เพิ่ม
                  <img
                    src="plus.png"
                    alt="เพิ่ม"
                    style={{
                      marginLeft: "0.5rem",
                      width: "1rem",
                      height: "1rem",
                    }}
                  />
                </button>
              )}
            </div>
          </div>
          {error ? (
            <div className="d-flex flew-column justify-content-center">
              <p>{error}</p>
            </div>
          ) : data.length > 0 ? (
            <>
              {data?.map((item, index) => {
                return (
                  <AdminReportCard
                    key={`alert-${item.id}`}
                    data={item}
                    alert={true}
                    cancelNotification={() => cancelNotification(item.id)}
                  />
                );
              })}
            </>
          ) : (
            <div className="d-flex flew-column justify-content-center">
              <p>ไม่มีข้อมูล</p>
            </div>
          )}
        </MDBContainer>
      )}
    </Layout>
  );
};

export default AdminAlert;
