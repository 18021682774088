import parse from "html-react-parser";
import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
} from "mdb-react-ui-kit";

import { TERM_OF_USE } from '../../libs/constants';
import { Layout } from "../../components";

const TermOfUse = () => {
  return (
    <Layout>
      <MDBContainer>
        <MDBCard className='my-4'>
          <MDBCardBody>
            <h2 className='text-uppercase text-center'>เงื่อนไขการใช้บริการ</h2>
            <h2 className='text-uppercase text-center mb-5'>(Term of use)</h2>
            {parse(TERM_OF_USE)}
          </MDBCardBody>
        </MDBCard>
      </MDBContainer>
    </Layout>
  );
};

export default TermOfUse;
