import { useState } from "react";
import { Link } from "react-router-dom";

import { MDBBtn, MDBIcon } from "mdb-react-ui-kit";
import { ModalConfirm, ModalAlert, ModalDetail } from "../components";

import { mappingTypeValue, mappingLevel } from "../libs/mappingValue";
import { DEFAULT_ERROR_MESSAGE } from "../libs/constants";

const AdminReportCard = ({ data, alert = false, cancelNotification }) => {
  const [showModal, setShowModal] = useState(false);
  const [modalValue, setModalValue] = useState({
    modalTitle: "",
    modalMessage: "",
    showModal: false,
    onModalClose: () => {},
  });

  const [openModalDetail, setOpenModalDetail] = useState(false);
  const toggleOpen = () => setOpenModalDetail(!openModalDetail);

  const deleteNotification = async () => {
    try {
      await cancelNotification();
      setShowModal(false);
    } catch (err) {
      setModalValue({
        modalTitle: "เกิดข้อผิดพลาด",
        modalMessage: DEFAULT_ERROR_MESSAGE,
        showModal: true,
        onModalClose: () => {
          setModalValue({ ...modalValue, showModal: false });
        },
      });
    }
  };

  const getLevelColor = (level) => {
    switch (level) {
      case "ต่ำมาก":
        return "#00C853";
      case "ต่ำ":
        return "#AEEA00";
      case "ปานกลาง":
        return "#FFD600";
      case "สูง":
        return "#FF8124";
      case "สูงมาก":
        return "#FF5858";
      default:
        return "#FFD600";
    }
  };

  const getTypeColor = (type) => {
    switch (type) {
      case "รออนุมัติ":
        return {
          color: "primary",
          icon: "clock",
          text: "รอตรวจสอบ",
        };
      case "อนุมัติแล้ว":
        return {
          color: "success",
          icon: "check-circle",
          text: "อนุมัติแล้ว",
        };
      case "ไม่อนุมัติ":
        return {
          color: "danger",
          icon: "times-circle",
          text: "ไม่อนุมัติข้อมูล",
        };
      default:
        return {
          color: "primary",
          icon: "clock",
          text: "รอตรวจสอบ",
        };
    }
  };

  const Approved = ({ id, level, send_notification, notification_update }) => {
    return (
      <div
        style={{
          backgroundColor: getLevelColor(mappingLevel(level)),
          borderRadius: "1rem 1rem 0 0",
        }}
        className="d-flex justify-content-between align-items-center p-2 px-3 shadow"
      >
        <h6 className="mt-1">รุนแรง : {mappingLevel(level)}</h6>
        {alert
          ? notification_update === 0 ? (
              <MDBBtn
                onClick={() => setShowModal(true)}
                className="fs-6 rounded-5"
                size="md"
              >
                ยกเลิก
              </MDBBtn>
            ): (
              <div className="bg-white rounded-5 px-2 py-1 opacity-80">
                <p className="m-0 mt-1">ยกเลิกแล้ว</p>
              </div>
            )
          : send_notification && (
              <div className="bg-white rounded-5 px-2 py-1 opacity-80">
                <h6 className="text-success mt-1">แจ้งเหตุแล้ว</h6>
              </div>
            )}
      </div>
    );
  };

  const ReportCard = ({
    onClick,
    id,
    type,
    level,
    title,
    typeOfAccident,
    location,
    description,
    daysAgo,
    send_notification,
    notification_update,
  }) => {
    return (
      <div className="shadow overflow-hidden rounded-6 mb-3">
        {(type === "อนุมัติแล้ว" || alert === true) && (
          <Approved
            id={id}
            level={level}
            send_notification={send_notification}
            notification_update={notification_update}
          />
        )}
        <div
          className="p-4 pb-2"
          onClick={onClick}
          style={{
            cursor: `${onClick ? "pointer" : null}`,
          }}
        >
          <div className="d-flex flex-column">
            <div className="d-flex align-items-center justify-content-between flex-wrap">
              <h5 className="text-truncate">{title}</h5>
              {!alert && (
                <div className="d-flex align-items-center mb-2">
                  <MDBIcon
                    className="me-2 mb-1"
                    fas
                    icon={getTypeColor(type).icon}
                    color={getTypeColor(type).color}
                  />
                  <h6 className={`text-${getTypeColor(type).color}`}>
                    {getTypeColor(type).text}
                  </h6>
                </div>
              )}
            </div>
            <div className="d-flex flex-wrap mt-2">
              {typeOfAccident.map((accident, index) => {
                return (
                  <div
                    key={index}
                    className="bg-black text-white px-3 py-2 rounded-5 me-2 mb-2 text-truncate"
                  >
                    {mappingTypeValue(accident)}
                  </div>
                );
              })}
            </div>
          </div>
          <h6 className="mb-2">{location}</h6>
          <h6 className="mb-4 text-truncate">{description}</h6>
          <h6>{daysAgo} วันที่แล้ว</h6>
        </div>
        <div
          className={alert ? `bg-primary` : `bg-${getTypeColor(type).color}`}
          style={{ height: "0.5rem" }}
        />
      </div>
    );
  };

  return (
    <>
      {alert ? (
        <>
          <ReportCard
            onClick={toggleOpen}
            id={data.id}
            type={data.type}
            level={data.level}
            title={data.title}
            typeOfAccident={data.typeOfAccident}
            location={data.location}
            description={data.description}
            daysAgo={Math.floor((new Date() - new Date(data.date_created)) / (1000 * 3600 * 24))}
            send_notification={data.send_notification}
            notification_update={data.notification_update}
          />
          <ModalAlert
            show={modalValue.showModal}
            setShow={(show) => (show ? null : modalValue.onModalClose())}
            title={modalValue.modalTitle}
            onClose={modalValue.onModalClose}
          />
          <ModalConfirm
            show={showModal}
            setShow={setShowModal}
            confirm={() => deleteNotification()}
          />
          <ModalDetail
            openModal={openModalDetail}
            toggleOpen={toggleOpen}
            title={data.title}
            type={data.typeOfAccident}
            image={data.image}
            description={data.description}
            location_title={data.location}
            date_created={data.date_created}
            damage_level={data.damage_level}
          />
        </>
      ) : (
        <Link to={`/report-detail?id=${data.id}`} className="text-black">
          <ReportCard
            id={data.id}
            type={data.type}
            level={data.level}
            title={data.title}
            typeOfAccident={data.typeOfAccident}
            location={data.location}
            description={data.description}
            daysAgo={data.daysAgo}
            send_notification={data.send_notification}
            notification_update={data.notification_update}
          />
        </Link>
      )}
    </>
  );
};
export default AdminReportCard;
