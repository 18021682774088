import { useState, useEffect } from "react";
import { ApiHelper } from "../../libs/api";
import { Layout, AdminReportCard, Loading } from "../../components";

import { MDBBtn, MDBContainer } from "mdb-react-ui-kit";

const AdminReport = () => {
  const [reportData, setReportData] = useState([]);
  const [error, setError] = useState(null);
  const [data, setData] = useState(reportData);
  const filters = ["ทั้งหมด", "รออนุมัติ", "อนุมัติแล้ว", "ไม่อนุมัติ"];
  const [selected, setSelected] = useState(filters[0]);
  const [loading, setLoaing] = useState(true);

  const callback = (select) => {
    if (selected === select || select === filters[0]) {
      setSelected(filters[0]);
      setData(reportData);
    } else {
      setSelected(select);
      const filtered = reportData.filter((item) => {
        return item.type === select;
      });
      setData(filtered);
    }
  };

  const fetchReport = async () => {
    try {
      const { success, data, error } = await ApiHelper.getEvents();
      if (success) {
        const rawData = data;
        const mappingData = rawData.map((item) => {
          const typeVerify =
            item.verified_by_staff === "true"
              ? "อนุมัติแล้ว"
              : item.verified_by_staff === "false"
              ? "ไม่อนุมัติ"
              : "รออนุมัติ";
          const daysAgo = Math.floor(
            (new Date() - new Date(item.date_created)) / (1000 * 3600 * 24)
          );
          const type = item.type.map((word) =>
            word === "custom" ? item.custom_type : word
          );
          return {
            id: item.id,
            title: item.title,
            description: item.description,
            location: item.location_title,
            typeOfAccident: type,
            daysAgo: daysAgo,
            level: item.damage_level,
            type: typeVerify,
            send_notification: item.send_notification,
            notification_update: item.notification_update,
          };
        });
        setReportData(mappingData);
      } else {
        if (error.extensions.code === "FORBIDDEN")
          setError("คุณไม่มีสิทธิ์เข้าถึงหน้านี้หรือดำเนินการนี้");
        else setError("เกิดข้อผิดพลาด");
      }
    } catch (error) {
      console.error("Error fetching admin report", error);
    } finally {
      setLoaing(false);
    }
  };

  useEffect(() => {
    fetchReport();
  }, []);

  useEffect(() => {
    setData(reportData);
  }, [reportData]);

  const FilterButton = ({ callback, selected, data }) => {
    return (
      <MDBBtn
        className={`rounded-7 p-1 p-sm-2 m-1 mt-0 ${
          selected ? "bg-primary" : "bg-secondary"
        }`}
        onClick={() => {
          if (callback) {
            callback(data);
          }
        }}
      >
        <h6 className="text-white px-1 my-1">{data}</h6>
      </MDBBtn>
    );
  };

  return (
    <Layout admin={true}>
      {loading ? (
        <Loading />
      ) : (
        <MDBContainer fluid className="bg-white">
          <div className="p-3 p-sm-4 w-100">
            <div className="d-flex align-items-center justify-content-between flex-wrap">
              <h2 className="text-uppercase text-left">
                รายการแจ้งข้อมูลข่าวสาร
              </h2>
              <div className="d-flex flex-wrap">
                {filters.map((filter) => (
                  <FilterButton
                    key={filter}
                    selected={filter === selected}
                    data={filter}
                    callback={callback}
                  />
                ))}
              </div>
            </div>
          </div>
          {error ? (
            <div className="d-flex flew-column justify-content-center">
              <p>{error}</p>
            </div>
          ) : data.length > 0 ? (
            <>
              {data?.map((item, index) => {
                return (
                  <AdminReportCard key={`report-${item.id}`} data={item} />
                );
              })}
            </>
          ) : (
            <div className="d-flex flew-column justify-content-center">
              <p>ไม่มีข้อมูล</p>
            </div>
          )}
        </MDBContainer>
      )}
    </Layout>
  );
};

export default AdminReport;
