import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthHelper } from "../../libs/auth";
import { ModalAlert, Layout } from "../../components";

import {
  MDBBtn,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBValidation,
  MDBInput,
} from "mdb-react-ui-kit";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [formValue, setFormValue] = useState({
    email: "",
  });
  const [modalValue, setModalValue] = useState({
    modalTitle: "",
    modalMessage: "",
    showModal: false,
    onModalClose: () => {},
  });

  const onChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { email } = formValue;
    if (email && email.includes("@") && !email.endsWith("@")) {
      AuthHelper.forgotPassword(email);
      setModalValue({
        modalTitle: "สำเร็จ",
        modalMessage: "ส่งอีเมลสำหรับเปลี่ยนรหัสผ่านแล้ว",
        showModal: true,
        onModalClose: () => navigate("/login"),
      });
    }
  };


  return (
    <Layout>
      <MDBContainer className="d-flex align-items-center justify-content-center">
        <MDBCard
          className="m-5"
          style={{ maxWidth: "600px", minWidth: "400px" }}
        >
          <MDBCardBody className="px-5">
            <h2 className="text-uppercase text-center mb-5">ลืมรหัสผ่าน</h2>
            <MDBValidation onSubmit={handleSubmit}>
              <MDBInput
                wrapperClass="mb-4"
                label="อีเมล"
                size="lg"
                id="input-email"
                type="email"
                name="email"
                value={formValue.email}
                onChange={onChange}
                required
              />
              <MDBBtn type="submit" className="mb-4 w-100" size="lg">
                รับอีเมลสำหรับเปลี่ยนรหัสผ่าน
              </MDBBtn>
            </MDBValidation>
          </MDBCardBody>
        </MDBCard>
        <ModalAlert
          show={modalValue.showModal}
          setShow={(show) => (show ? null : modalValue.onModalClose())}
          title={modalValue.modalTitle}
          onClose={modalValue.onModalClose}
        >
          {modalValue.modalMessage}
        </ModalAlert>
      </MDBContainer>
    </Layout>
  );
};

export default ForgotPassword;
