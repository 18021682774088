import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiHelper } from "../../libs/api";
import { DEFAULT_ERROR_MESSAGE } from "../../libs/constants";
import { ModalAlert, Layout } from "../../components";

import {
  MDBBtn,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBValidation,
  MDBInput,
  MDBIcon,
} from "mdb-react-ui-kit";

const ChangePassword = () => {
  const [formValue, setFormValue] = useState({
    password: "",
    repeatPassword: "",
  });
  const navigate = useNavigate();

  const [modalValue, setModalValue] = useState({
    modalTitle: "",
    modalMessage: "",
    showModal: false,
    onModalClose: () => {},
  });

  const onChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { password, repeatPassword } = formValue;
    if (password && repeatPassword) {
      if (password !== repeatPassword) {
        setModalValue({
          modalTitle: "เกิดข้อผิดพลาด",
          modalMessage: "รหัสผ่านไม่ตรงกัน กรุณากรอกรหัสผ่านใหม่อีกครั้ง",
          showModal: true,
          onModalClose: () =>
            setModalValue({ ...modalValue, showModal: false }),
        });
      } else if (password.length < 6) {
        setModalValue({
          modalTitle: "เกิดข้อผิดพลาด",
          modalMessage: "รหัสผ่านต้องมีความยาวตั้งแต่ 6 ตัวอักษรขึ้นไป",
          showModal: true,
          onModalClose: () =>
            setModalValue({ ...modalValue, showModal: false }),
        });
      } else if (password) {
        const { success} = await ApiHelper.updatePassword({
          password: password,
        });
        if (success) {
          setModalValue({
            modalTitle: "สำเร็จ",
            modalMessage: "เปลี่ยนรหัสผ่านสำเร็จ",
            showModal: true,
            onModalClose: () => navigate("/"),
          });
        } else {
          setModalValue({
            modalTitle: "เกิดข้อผิดพลาด",
            modalMessage: DEFAULT_ERROR_MESSAGE,
            showModal: true,
            onModalClose: () =>
              setModalValue({ ...modalValue, showModal: false }),
          });
        }
      }
    }
  };


  return (
    <Layout>
      <MDBContainer className="d-flex align-items-center justify-content-center">
        <MDBCard className="m-5" style={{ maxWidth: "400px" }}>
          <MDBCardBody className="px-5">
            <div className="d-flex align-items-center mb-1">
              <MDBBtn
                onClick={() => navigate(-1)}
                tag="a"
                color="none"
                className="m-2"
              >
                <MDBIcon fas icon="chevron-left" size="lg" />
              </MDBBtn>
              <h2 className="text-uppercase">เปลี่ยนรหัสผ่าน</h2>
            </div>
            <h6 className="text-uppercase mb-3">
              โปรดกรอกรหัสผ่านที่ต้องการเปลี่ยนให้ครบถ้วน
            </h6>
            <img
              src="/lock-password.png"
              alt="info"
              style={{
                width: "100%",
                height: "100%",
              }}
            />

            <MDBValidation onSubmit={handleSubmit}>
              <MDBInput
                wrapperClass="mb-4"
                label="รหัสผ่านใหม่"
                size="lg"
                id="input-password"
                type="password"
                name="password"
                value={formValue.password}
                onChange={onChange}
                required
              />
              <MDBInput
                wrapperClass="mb-4"
                label="ยืนยันรหัสผ่านใหม่"
                size="lg"
                id="input-repeat-password"
                type="password"
                name="repeatPassword"
                value={formValue.repeatPassword}
                onChange={onChange}
                required
              />
              <MDBBtn
                type="submit"
                color="danger"
                className="mb-4 w-100"
                size="lg"
              >
                เปลี่ยนรหัสผ่าน
              </MDBBtn>
            </MDBValidation>
          </MDBCardBody>
        </MDBCard>
        <ModalAlert
          show={modalValue.showModal}
          setShow={(show) => (show ? null : modalValue.onModalClose())}
          title={modalValue.modalTitle}
          onClose={modalValue.onModalClose}
        >
          {modalValue.modalMessage}
        </ModalAlert>
      </MDBContainer>
    </Layout>
  );
};

export default ChangePassword;
